/* tslint:disable */
export const localEn = {
  'LANG_DE': 'German',
  'LANG_EN': 'English',
  'LANG_ES': 'Spanish',
  'LANG_FR': 'French',
  'LANG_IT': 'Italian',
  'LANG_NL': 'Dutch',
  'MONDAY': 'Monday',
  'TUESDAY': 'Tuesday',
  'WEDNESDAY': 'Wednesday',
  'THURSDAY': 'Thursday',
  'FRIDAY': 'Friday',
  'SATURDAY': 'Saturday',
  'SUNDAY': 'Sunday',
  'PRINT': 'Print',
  'CHART': 'Chart',
  'IMPERSONATE_USER': 'Impersonate User',
  'LEAVE_IMPERSONATION': 'Leave Impersonation',
  'MY_PROFILE': 'My Profile',
  'LOGOUT': 'Log Out',
  'RUN_AS': 'as',
  'CLOSE': 'Close',
  'BLOCKED': 'Blocked',
  'AUTHORIZED': 'Authorized',
  'LIMITED': 'Limited',
  'RESTRICTION_PLANNING': 'Update schedule',
  'IMPERSONATE_DIALOG': 'Impersonate user list dialog',
  'IMPERSONATE_DIALOG_TITLE': 'Impersonate User',
  'IMPERSONATE_DIALOG_EXPLAIN_P1':
    'With this option you can browse the Portal like if you were connected with another user. This option is only available for administrators.',
  'IMPERSONATE_DIALOG_EXPLAIN_P2':
    'Any action performed under "impersonation" will be done with your identity. The "impersonation" is just a simulation of what other users can see.',
  'SHOW_X_ELEMENTS_START': 'Display',
  'SHOW_X_ELEMENTS_END': 'elements',
  'SEARCH': 'Search',
  'NAME': 'Name',
  'EMAIL': 'Email',
  'ROLES_GROUP': 'Roles group',
  'IMPERSONATE': 'Impersonate',
  'CANCEL': 'Cancel',
  'PAGINATION_INFO': 'Showing {{from}} to {{to}} of {{filteredLength}} elements',
  'FILTERED_PAGINATION_INFO': 'Showing {{from}} to {{to}} of {{filteredLength}} elements (filtered from {{totalLength}} elements)',
  'CLIENT_SELECTOR_PLACEHOLDER': 'Change Client...',
  'DASHBOARD': 'Dashboard',
  'USERS': 'Users',
  'CREATED': 'Created',
  'UPDATED': 'Updated',
  'LASTRUN': 'Envoyé le',
  'FREQUENCY': 'Frequency',
  'DAILY': 'Every day',
  'WEEKLY': '1st day of week',
  'MONTHLY': '1st day of month',
  'All': 'All',
  'SERVICES': 'Services',
  'ACTIVE': 'Active',
  'DELETE': 'Delete',
  'SEND': 'Test',
  'FILTERED': 'Filtered',
  'NOT_FILTERED': 'All',
  'EXCEL_DATA': 'Export Excel',
  'MY_PROFILE_TITLE': 'My profile',
  'MY_PROFILE_PERSONAL_INFO': 'My personal Info',
  'MY_PROFILE_CHANGE_AVATAR': 'Change my avatar',
  'MY_PROFILE_CHANGE_PASSWORD': 'Change my password',
  'MY_PROFILE_IDENTITIES': 'My third party logins',
  'MY_PROFILE_USER_NAME': 'Name',
  'MY_PROFILE_USER_NAME_PLACEHOLDER': ' ',
  'MY_PROFILE_USER_NAME_REQUIRED': 'You must provide your name.',
  'MY_PROFILE_USER_EMAIL': 'E-Mail',
  'MY_PROFILE_USER_EMAIL_PLACEHOLDER': ' ',
  'MY_PROFILE_USER_EMAIL_REQUIRED': 'You must provide your e-mail address.',
  'MY_PROFILE_USER_EMAIL_INVALID': 'Your e-mail address is not valid.',
  'MY_PROFILE_USER_CULTURE': 'Culture',
  'MY_PROFILE_USER_CULTURE_REQUIRED': 'You must select your culture.',
  'MY_PROFILE_USER_GENDER': 'Gender',
  'MY_PROFILE_USER_TIMEZONE': 'Time zone',
  'MY_PROFILE_USER_TIMEZONE_REQUIRED': 'You must select your time zone.',
  'MY_PROFILE_USER_PHONE': 'Phone',
  'MY_PROFILE_USER_PHONE_PLACEHOLDER': ' ',
  'MY_PROFILE_USER_FAX': 'Fax',
  'MY_PROFILE_USER_FAX_PLACEHOLDER': ' ',
  'MY_PROFILE_USER_MOBILE': 'Mobile',
  'MY_PROFILE_USER_MOBILE_PLACEHOLDER': ' ',
  'MY_PROFILE_USER_SKYPE': 'Skype',
  'MY_PROFILE_USER_SKYPE_PLACEHOLDER': ' ',
  'MY_PROFILE_USER_DEPARTMENT': 'Department',
  'MY_PROFILE_USER_DEPARTMENT_PLACEHOLDER': ' ',
  'MY_PROFILE_UNSAVED_CHANGES_START': 'You have unsaved changes in the following tab(s):',
  'MY_PROFILE_UNSAVED_CHANGES_END': 'Are you sure you want to leave this page without saving your changes?',
  'GENDER_UNDEFINED': ' ',
  'GENDER_WOMAN': 'Woman',
  'GENDER_MAN': 'Man',
  'SAVE_CHANGES': 'Save Change',
  'SAVING_CHANGES': 'Saving Changes...',
  'EXTERNAL_ID_LINK': 'Link',
  'EXTERNAL_ID_UNLINK': 'Unlink',
  'EXTERNAL_ID_GOOGLE': 'Link your Google® account.',
  'EXTERNAL_ID_MSLIVE': 'Link your Microsoft Live® account',
  'CHANGE_PASSWORD_STRENGTH': 'Password Security Level',
  'PASSWORD_STRENGTH_VERY_WEAK': 'Very weak',
  'PASSWORD_STRENGTH_VERY_WEAK_DESC': 'Keep out a typical attacker for minutes.',
  'PASSWORD_STRENGTH_WEAK': 'Weak',
  'PASSWORD_STRENGTH_WEAK_DESC': 'Crackable by a typical home computer in a week.',
  'PASSWORD_STRENGTH_REASONABLE': 'Reasonable',
  'PASSWORD_STRENGTH_REASONABLE_DESC': 'A specialized computer could get this in one year.',
  'PASSWORD_STRENGTH_STRONG': 'Strong',
  'PASSWORD_STRENGTH_STRONG_DESC': 'Resistant to a large, coordinated attack (botnet) for over a year.',
  'PASSWORD_STRENGTH_VERY_STRONG': 'Very strong',
  'PASSWORD_STRENGTH_VERY_STRONG_DESC':
    'Nearly impossible to brute force, given more than all of the computing power in the world, optimized algorithms, specialized hardware and a thousand years.',
  'CHANGE_PASSWORD_CURRENT': 'Current Password',
  'CHANGE_PASSWORD_CURRENT_REQUIRED': 'The actual password is required.',
  'CHANGE_PASSWORD_NEW': 'New Password',
  'CHANGE_PASSWORD_NEW_REQUIRED': 'The new password is required.',
  'CHANGE_PASSWORD_NEW_MINLENGTH': 'The new password must contain at least 4 characters long.',
  'CHANGE_PASSWORD_NEW_MUST_BE_DIFFERENT': 'The new password must be different from the actual password.',
  'CHANGE_PASSWORD_VALIDATION_ERROR_ME': 'A validation error occurred while changing your password.',
  'CHANGE_PASSWORD_CONFIRM': 'Re-type New Password',
  'CHANGE_PASSWORD_CONFIRM_MISMATCH': 'The new password and its confirmation does not match.',
  'CHANGE_PASSWORD_SAVE': 'Save Password',
  'CHANGE_PASSWORD_SAVING': 'Saving Password...',
  'CHANGE_AVATAR_YOURS': 'Your avatar',
  'CHANGE_AVATAR_SELECT': 'Select image',
  'CHANGE_AVATAR_CHANGE': 'Change',
  'CHANGE_AVATAR_REMOVE': 'Remove',
  'CHANGE_AVATAR_SAVE': 'Submit',
  'CHANGE_AVATAR_SAVING': 'Submitting...',
  'ASSIGN_GROUP_DIALOG': 'Assign Users to Group of Roles Dialog',
  'ASSIGN_GROUP_DIALOG_TITLE': 'Assign user(s) to group',
  'ASSIGN_GROUP_DIALOG_SINGLE': 'Assign this user to a group of roles.',
  'ASSIGN_GROUP_DIALOG_MULTI': 'Assign these users to a group of roles.',
  'ASSIGN_GROUP_DIALOG_GROUP': 'Group:',
  'ASSIGN_GROUP_DIALOG_NOSELECTION': '(select a group)',
  'ASSIGN_GROUP_DIALOG_ACCEPT': 'Assign',
  'AUTH0_INFO_TITLE': 'Connections Info',
  'AUTH0_INFO_HISTORY': 'History',
  'AUTH0_INFO_MAP': 'Locations',
  'AUTH0_LOGS_DURATION': 'Max. Log Storage: 10 days.',
  'AUTH0_LOGS_EVENT': 'Event',
  'AUTH0_LOGS_WHEN': 'When',
  'AUTH0_LOGS_APP': 'App',
  'AUTH0_LOGS_IDP': 'Identity Provider',
  'AUTH0_LOGS_IP': 'IP Address',
  'AUTH0_LOGS_FROM': 'From',
  'AUTH0_MAP_SUCCESS_LOGIN': 'Success Login',
  'CONFIRM_DELETE_USERS_DIALOG': 'Delete User(s) Confirmation Dialog',
  'CONFIRM_DELETE_USERS_DIALOG_TITLE': 'Delete Confirmation',
  'CONFIRM_DELETE_USERS_DIALOG_SINGLE': 'Are you sure you want to delete this user?',
  'CONFIRM_DELETE_USERS_DIALOG_MULTI': 'Are you sure you want to delete these users?',
  'CONFIRM_DELETE_USERS_DIALOG_ACCEPT': 'Delete',
  'CREATE_USER_DIALOG': 'Create new user dialog',
  'CREATE_USER_DIALOG_TITLE': 'Create user',
  'CREATE_USER_DIALOG_ACCEPT': 'Create',
  'CREATE_USER_DIALOG_NAME': 'Name',
  'CREATE_USER_DIALOG_NAME_PLACEHOLDER': ' ',
  'CREATE_USER_DIALOG_NAME_REQUIRED': 'The user name is required.',
  'CREATE_USER_DIALOG_EMAIL': 'E-mail',
  'CREATE_USER_DIALOG_EMAIL_PLACEHOLDER': ' ',
  'CREATE_USER_DIALOG_EMAIL_REQUIRED': 'The e-mail address is required.',
  'CREATE_USER_DIALOG_EMAIL_INVALID': 'The e-mail address is not valid.',
  'CREATE_USER_DIALOG_ROLES_GROUP': 'Roles group',
  'CREATE_USER_DIALOG_ROLES_GROUP_NONE': ' ',
  'CREATE_USER_DIALOG_ROLES_GROUP_REQUIRED': 'The group role is required.',
  'CREATE_USER_DIALOG_OFFICE': 'Offices',
  'CREATE_USER_DIALOG_IS_STOREVER': 'Is MCube',
  'CREATE_USER_DUPLICATE': 'The user already exists. You have been redirected to the existing user.',
  'OFFICE_NONE': ' ',
  'OFFICE_BE': 'Belgium',
  'OFFICE_FR': 'France',
  'OFFICE_ES': 'Spain',
  'OFFICE_DE': 'Germany',
  'OFFICE_CN': 'China',
  'OFFICE_IT': 'MCube Italia',
  'EDIT_USER_NAME': 'Name',
  'EDIT_USER_NAME_PLACEHOLDER': ' ',
  'EDIT_USER_NAME_REQUIRED': 'The user name is required.',
  'EDIT_USER_EMAIL': 'E-Mail',
  'EDIT_USER_EMAIL_PLACEHOLDER': ' ',
  'EDIT_USER_EMAIL_REQUIRED': 'The e-mail address is required.',
  'EDIT_USER_EMAIL_INVALID': 'The e-mail address is not valid.',
  'EDIT_USER_ROLES_GROUP': 'Roles group',
  'EDIT_USER_ROLES_GROUP_NONE': ' ',
  'EDIT_USER_ROLES_GROUP_REQUIRED': 'The group role is required.',
  'EDIT_USER_OFFICE': 'Office',
  'EDIT_USER_CULTURE': 'Culture',
  'EDIT_USER_CULTURE_REQUIRED': 'The culture is required.',
  'EDIT_USER_GENDER': 'Gender',
  'EDIT_USER_TIMEZONE': 'Time zone',
  'EDIT_USER_TIMEZONE_REQUIRED': 'The time zone is required.',
  'EDIT_USER_PHONE': 'Phone',
  'EDIT_USER_PHONE_PLACEHOLDER': ' ',
  'EDIT_USER_FAX': 'Fax',
  'EDIT_USER_FAX_PLACEHOLDER': ' ',
  'EDIT_USER_MOBILE': 'Mobile',
  'EDIT_USER_MOBILE_PLACEHOLDER': ' ',
  'EDIT_USER_SKYPE': 'Skype',
  'EDIT_USER_SKYPE_PLACEHOLDER': ' ',
  'EDIT_USER_DEPARTMENT': 'Department',
  'EDIT_USER_DEPARTMENT_PLACEHOLDER': ' ',
  'IMPORT_USERS_DIALOG': 'Import users from other clients dialog',
  'IMPORT_USERS_DIALOG_TITLE': 'Import user(s)',
  'IMPORT_USERS_DIALOG_CLIENT': 'Client:',
  'IMPORT_USERS_DIALOG_CLIENT_NONE': '(select a client)',
  'IMPORT_USERS_DIALOG_USER': 'User:',
  'IMPORT_USERS_DIALOG_ROLES_GROUP': 'Roles Group:',
  'IMPORT_USERS_DIALOG_ROLES_GROUP_NONE': '(select a group)',
  'IMPORT_USERS_DIALOG_ACCEPT': 'Import',
  'USERS_LIST_TITLE': 'Users',
  'USERS_LIST_CREATE': 'Create',
  'USERS_LIST_IMPORT': 'Import',
  'USERS_LIST_FILTER': 'Filter',
  'EXPANDER_TITLE': 'Expand/Collapse',
  'USERS_LIST_FILTER_NAME': 'Name',
  'USERS_LIST_FILTER_EMAIL': 'Email',
  'USERS_LIST_FILTER_ACTIVE': 'Active',
  'USERS_LIST_FILTER_ACTIVE_ALL': ' ',
  'YES': 'Yes',
  'NO': 'No',
  'USERS_LIST_FILTER_ROLES_GROUP': 'Roles Group',
  'USERS_LIST_FILTER_ROLES_GROUP_ALL': ' ',
  'USERS_LIST_FILTER_SEARCH': 'Search',
  'USERS_LIST_FILTER_RESET': 'Reset',
  'USERS_LIST_SELECT_PAGE': 'Select Page',
  'USERS_LIST_UNSELECT_PAGE': 'Unselect Page',
  'USERS_LIST_ACTIONS': 'Actions',
  'USERS_LIST_TOGGLE_STATUS': 'Toggle Status',
  'USERS_LIST_ASSIGN_ROLES_GROUP': 'Assign Roles Group',
  'USERS_LIST_LINK_AUDIO_PLAYERS': 'Link to Audio Player(s)',
  'USERS_LIST_UNLINK_AUDIO_PLAYERS': 'Unlink from Audio Player(s)',
  'USERS_LIST_LINK_VIDEO_PLAYERS': 'Link to Video Player(s)',
  'USERS_LIST_UNLINK_VIDEO_PLAYERS': 'Unlink from Video Player(s)',
  'USERS_LIST_DELETE': 'Delete',
  'USERS_LIST_COLUMN_AVATAR': 'Avatar',
  'USERS_LIST_COLUMN_NAME': 'Name',
  'USERS_LIST_COLUMN_EMAIL': 'Email',
  'USERS_LIST_COLUMN_ROLES_GROUP': 'Roles Group',
  'USERS_LIST_COLUMN_NUMBER_AUDIO': '# Audios',
  'USERS_LIST_COLUMN_NUMBER_VIDEO': '# Videos',
  'USERS_LIST_COLUMN_STATUS': 'Status',
  'USER_NOT_FOUND': 'The user does not exist anymore.',
  'USER_SIDEBAR_BLOCK': 'Block User',
  'USER_SIDEBAR_UNBLOCK': 'Unblock User',
  'USER_SIDEBAR_DELETE': 'Delete User',
  'USER_SIDEBAR_RESET_PASSWORD': 'Reset Password',
  'USER_SIDEBAR_USER_INFO': 'User Info',
  'USER_SIDEBAR_SUBSCRIPTION_INFO': 'Subscriptions Info',
  'USER_SIDEBAR_CONNECTION_INFO': 'Connections Info',
  'USER_SUBSCRIPTIONS_ASSIGN_ALL': 'Assign all players (including the new ones that will be added later).',
  'USER_SUBSCRIPTIONS_IMPORT': 'Import from another user',
  'USER_SUBSCRIPTIONS_ASSIGNED': 'Assigned players',
  'USER_SUBSCRIPTIONS_ASSIGNED_TEXT': 'Drag player from unassigned players list to assign it to the user.',
  'USER_SUBSCRIPTIONS_ASSIGNED_FILTER': 'Filter assigned players',
  'USER_SUBSCRIPTIONS_ASSIGNED_EMPTY': 'The user has not access to any player.',
  'USER_SUBSCRIPTIONS_ASSIGNED_TOGGLE': 'Unassign all players',
  'USER_SUBSCRIPTIONS_UNASSIGNED': 'Unassigned players',
  'USER_SUBSCRIPTIONS_UNASSIGNED_TEXT': 'Drag player from assigned players list to unassign it from the user.',
  'USER_SUBSCRIPTIONS_UNASSIGNED_FILTER': 'Filter unassigned players',
  'USER_SUBSCRIPTIONS_UNASSIGNED_EMPTY': 'The user has access to all the players.',
  'USER_SUBSCRIPTIONS_UNASSIGNED_TOGGLE': 'Assign all players',
  'USER_SUBSCRIPTIONS_IMPORT_DIALOG': 'Import players from another user',
  'USER_SUBSCRIPTIONS_IMPORT_DIALOG_TITLE': 'Import players from another user',
  'USER_SUBSCRIPTIONS_IMPORT_DIALOG_USER': 'Select the user from which the players should be imported',
  'USER_SUBSCRIPTIONS_IMPORT_DIALOG_USER_NONE': '-- Select a user --',
  'USER_SUBSCRIPTIONS_IMPORT_DIALOG_ACCEPT': 'Import',
  'USERS_LINK_SUBSCRIPTIONS_DIALOG': 'Link users to {{type}} players dialog.',
  'USERS_UNLINK_SUBSCRIPTIONS_DIALOG': 'Unlink users from {{type}} players dialog.',
  'USERS_LINK_SUBSCRIPTIONS_DIALOG_TITLE': 'Link to {{type}} players',
  'USERS_UNLINK_SUBSCRIPTIONS_DIALOG_TITLE': 'Unlink from {{type}} players',
  'USERS_LINK_SUBSCRIPTIONS_DIALOG_SINGLE': 'Select the player(s) you want to link to this user.',
  'USERS_LINK_SUBSCRIPTIONS_DIALOG_MULTI': 'Select the player(s) you want to link to these users.',
  'USERS_UNLINK_SUBSCRIPTIONS_DIALOG_SINGLE': 'Select the player(s) you want to unlink from this user.',
  'USERS_UNLINK_SUBSCRIPTIONS_DIALOG_MULTI': 'Select the player(s) you want to unlink from these users.',
  'USERS_UN_LINK_SUBSCRIPTIONS_USERS': 'Users',
  'USERS_UN_LINK_SUBSCRIPTIONS_PLAYERS': 'Players',
  'USERS_UN_LINK_SUBSCRIPTIONS_SELECT_ALL': 'Select All',
  'USERS_UN_LINK_SUBSCRIPTIONS_SELECT_NONE': 'Select None',
  'USERS_UN_LINK_SUBSCRIPTIONS_LINK': 'Link',
  'USERS_UN_LINK_SUBSCRIPTIONS_UNLINK': 'Unlink',
  'USER_INFO_TITLE': 'User Info',
  'USER_INFO_PERSONAL_INFO': 'Personal Info',
  'USER_INFO_AVATAR': 'Change Avatar',
  'USER_INFO_AUDIO_PLAYERS': 'Audio Players',
  'USER_INFO_VIDEO_PLAYERS': 'Video Players',
  'USER_INFO_UNSAVED_CHANGES_START': 'You have unsaved changes in the following tab(s):',
  'USER_INFO_UNSAVED_CHANGES_END': 'Are you sure you want to leave this page without saving your changes?',
  'TOAST_ERROR_TITLE': 'Error',
  'TOAST_SUCCESS_TITLE': 'Success',
  'TOAST_INFO_TITLE': 'Information',
  'TOAST_WARNING_TITLE': 'Warning',
  'TOAST_ACCESS_DENIED_TITLE': 'Access Denied',
  'GROUP_ROLES_LOAD_ERROR': 'An error occurred while fetching group roles for the current client.',
  'GROUP_ROLES_TEMPLATES_LOAD_ERROR': 'An error occurred while fetching group roles templates for the current client.',
  'CULTURES_LOAD_ERROR': 'An error occurred while loading available cultures.',
  'OFFICES_LOAD_ERROR': 'An error occurred while loading available Offices.',
  'MY_CLIENTS_LOAD_ERROR': 'Cannot load the list of clients you have access to.',
  'SUBSCRIPTIONS_LIST_LOAD_AUDIO_ERROR': 'An error occurred while fetching audio subscriptions for the current client.',
  'SUBSCRIPTIONS_LIST_LOAD_VIDEO_ERROR': 'An error occurred while fetching video subscriptions for the current client.',
  'TIMEZONES_LOAD_ERROR': 'An error occurred while loading available timezones.',
  'USER_LOAD_ERROR_ME': 'An error occurred while loading your profile.',
  'USER_LOAD_ERROR': 'An error occurred while loading the user details.',
  'USER_SAVE_SUCCESS_ME': 'Your profile has been successfully saved.',
  'USER_SAVE_SUCCESS': 'The user details has been successfully saved.',
  'USER_SAVE_ERROR_ME': 'An error occurred while saving your profile.',
  'USER_SAVE_VALIDATION_ERROR_ME': 'A validation error occurred while saving your profile.',
  'USER_SAVE_ERROR': 'An error occurred while saving the user details.',
  'USER_SAVE_VALIDATION_ERROR': 'A validation error occurred while saving the user details.',
  'USER_CHANGE_AVATAR_SUCCESS_ME': 'Your avatar has been successfully changed.',
  'USER_CHANGE_AVATAR_SUCCESS': 'The user\'s avatar has been successfully changed.',
  'USER_CHANGE_AVATAR_ERROR_ME': 'An error occurred while saving your avatar.',
  'USER_CHANGE_AVATAR_ERROR': 'An error occurred while saving the user\'s avatar.',
  'USER_CHANGE_PASSWORD_SUCCESS': 'Your password has been successfully updated.',
  'USER_CHANGE_PASSWORD_ERROR': 'An error occurred while changing your password.',
  'USER_RESET_PASSWORD_SUCCESS': 'The user password has been successfully reset.',
  'USER_RESET_PASSWORD_ERROR': 'An error occurred while resetting the user password.',
  'USER_TOGGLE_ACTIVE_SUCCESS': 'User has been successfully updated.',
  'USER_TOGGLE_ACTIVE_ERROR': 'An error occurred while updating the user.',
  'USER_REMOVE_SUCCESS': 'The user has been successfully deleted from the client.',
  'USER_REMOVE_ERROR': 'An error occurred while deleting the user.',
  'USER_REMOVE_SELF_ERROR': 'You cannot delete yourself.',
  'USER_LOAD_AUDIO_SUBSCRIPTIONS_ERROR': 'An error occurred while loading user\'s audio subscriptions.',
  'USER_LOAD_VIDEO_SUBSCRIPTIONS_ERROR': 'An error occurred while loading user\'s video subscriptions.',
  'USER_UPDATE_AUDIO_SUBSCRIPTIONS_SUCCESS': 'The user\'s audio subscriptions have been successfully updated.',
  'USER_UPDATE_AUDIO_SUBSCRIPTIONS_ERROR': 'An error occurred while updating the user\'s audio subscriptions',
  'USER_UPDATE_VIDEO_SUBSCRIPTIONS_SUCCESS': 'The user\'s video subscriptions have been successfully updated.',
  'USER_UPDATE_VIDEO_SUBSCRIPTIONS_ERROR': 'An error occurred while updating the user\'s video subscriptions',
  'USER_IMPORT_AUDIO_SUBSCRIPTIONS_SUCCESS': 'The audio subscriptions have been successfully imported.',
  'USER_IMPORT_AUDIO_SUBSCRIPTIONS_ERROR': 'An error occurred while importing audio subscriptions from another user.',
  'USER_IMPORT_VIDEO_SUBSCRIPTIONS_SUCCESS': 'The video subscriptions have been successfully imported.',
  'USER_IMPORT_VIDEO_SUBSCRIPTIONS_ERROR': 'An error occurred while importing video subscriptions from another user.',
  'USER_CONTEXT_IMPERSONATE_ACCESS_DENIED': 'You cannot impersonate another user.',
  'USER_CONTEXT_IMPERSONATE_SUCCESS': 'You are now impersonating {{username}} for the next {{duration}} minutes or until you leave impersonation manually.',
  'USER_CONTEXT_IMPERSONATE_ERROR': 'An error occurred while trying to impersonate {{username}}.',
  'USER_CONTEXT_GET_ROLES_ERROR': 'Unable to retrieve your roles. Please contact your administrator.',
  'USER_CONTEXT_GET_IMPERSONABLE_ERROR': 'Unable to retrieve the impersonable users for client "{{clientName}}". Please context your administrator.',
  'USERS_LOAD_ERROR': 'An error occurred while fetching the users.',
  'USERS_LOAD_BAD_REQUEST_ERROR': 'There is an error in the search filter.',
  'USERS_DELETE_ERROR': 'There was one or more error(s) deleting the selected user(s).',
  'USERS_DELETE_SELF_ERROR': 'You cannot delete yourself.',
  'USERS_DELETE_SUCCESS': 'The selected user(s) have been deleted.',
  'USERS_REFRESH_ERROR': 'An error occurred while refreshing the users.',
  'USERS_IMPORT_ERROR': 'An error occurred while importing users.',
  'USERS_IMPORT_BAD_REQUEST_ERROR': 'A validation error occurred while importing users.',
  'USERS_IMPORT_SUCCESS': 'User(s) have been imported successfully.',
  'USERS_CREATE_SUCCESS': 'The user has been created successfully.',
  'USERS_CREATE_ERROR': 'An error occurred while creating the new user.',
  'USERS_CREATE_BAD_REQUEST_ERROR': 'A validation error occurred while creating the new user.',
  'USERS_PATCH_SUCCESS': 'Selected user(s) has been successfully updated.',
  'USERS_PATCH_ERROR': 'An error occurred while updating the selected user(s).',
  'USERS_UN_LINK_SUBSCRIPTIONS_SUCCESS': 'Selected user(s) has been successfully updated.',
  'USERS_UN_LINK_SUBSCRIPTIONS_ERROR': 'An error occurred while updating the selected user(s).',
  'AUTH_UNLINK_SUCCESS': 'Your social account has been successfully unlinked.',
  'AUTH_UNLINK_ERROR': 'An error occurred while trying to unlink your social account.',
  'AUTH_GET_USER_INFO_ERROR': 'An error occurred while retrieving your user info from authentication provider: [{{code}}]] {{description}}',
  'AUTH_UPDATE_PROFILE_ERROR': 'An error occurred while trying to update your profile.',
  'AUTH_LOGS_LOAD_ERROR': 'An error occurred while retrieving the authentication logs for the user.',
  'AUTH_LINK_ERROR': 'An error occurred while linking your account to the social account.',
  'AUTH_LINK_ERROR_DETAILS': 'An error occurred while linking your account to the social account: [{{code}}] {{description}}',
  'ERROR_PAGE_NOT_FOUND': '404',
  'ERROR_PAGE_NOT_FOUND_TITLE': 'Houston, we have a problem.',
  'ERROR_PAGE_NOT_FOUND_PARA': 'Actually, the page you are looking for does not exist.',
  'ERROR_PAGE_RETURN_HOME': 'Return home',
  'ERROR_PAGE_ACCESS_DENIED': '403',
  'ERROR_PAGE_ACCESS_DENIED_TITLE': 'Houston, you have a problem.',
  'ERROR_PAGE_ACCESS_DENIED_PARA': 'Actually, you do not have access to the page.',
  'AUTH_LOGIN_GENERIC_ERROR': 'Something went wrong when trying to connect you. Please contact your administrator.',
  'AUTH_LOGIN_WRONG_CREDENTIALS': 'Wrong e-mail or password.',
  'AUTH_LOGIN_BLOCKED': 'Your account is blocked. Please contact your administrator.',
  'AUTH_LOGIN_LEAKED': 'This login has been blocked because your password has been leaked in another website. Please contact your administrator.',
  'AUTH_LOGIN_SOCIAL_FIRST': 'You cannot login with a social account which is not linked to an existing local account.',
  'AUTH_LOGIN_ACCESS_DENIED': 'Access is denied. Please contact your administrator.',
  'AUTH_LOGIN_INVALID_TOKEN': 'Invalid Token:',
  'AUTH_LOGIN_AUTO_TITLE': 'Please wait...',
  'AUTH_LOST_PASSWORD_TITLE': 'Forget Password ?',
  'AUTH_RENEW_PASSWORD_TITLE': 'The link as expired, please re-apply.',
  'AUTH_LOST_PASSWORD_PARA': 'Enter your e-mail address below to reset your password.',
  'AUTH_LOST_PASSWORD_ERROR': 'An error occurred while sending your request.',
  'AUTH_LOST_PASSWORD_SUCCESS': 'An e-mail with a reset password link has been sent to you.',
  'AUTH_GOTO_LOGIN_PAGE': 'Click here to go to the login page',
  'AUTH_LOST_PASSWORD_EMAIL': 'Email',
  'AUTH_LOST_PASSWORD_EMAIL_REQUIRED': 'The e-mail is required.',
  'AUTH_LOST_PASSWORD_EMAIL_INVALID': 'The e-mail is not valid.',
  'AUTH_LOST_PASSWORD_BACK': 'Back',
  'AUTH_LOST_PASSWORD_SUBMIT': 'Submit',
  'AUTH_LOST_PASSWORD_SUBMITTING': 'Submitting...',
  'AUTH_RESET_PASSWORD_TITLE': 'Reset Password',
  'AUTH_RESET_PASSWORD_PARA': 'Enter your new password and confirm it.',
  'AUTH_RESET_PASSWORD_ERROR': 'An error occurred while sending your new password.',
  'AUTH_RESET_PASSWORD_SUCCESS': 'Your password has been reset.',
  'AUTH_RESET_PASSWORD_NEW': 'New Password',
  'AUTH_RESET_PASSWORD_NEW_REQUIRED': 'The new password is required.',
  'AUTH_RESET_PASSWORD_NEW_MINLENGTH': 'The new password must contain at least 4 characters long.',
  'AUTH_RESET_PASSWORD_CONFIRM': 'Password Confirmation',
  'AUTH_RESET_PASSWORD_CONFIRM_MISMATCH': 'The new password and its confirmation does not match.',
  'AUTH_RESET_PASSWORD_SUBMIT': 'Submit',
  'AUTH_RESET_PASSWORD_SUBMITTING': 'Submitting...',
  'PLAYERS': 'Players',
  'PAGE_SIZE_ALL': 'All',
  'ALL_SUBSCRIPTIONS': 'All',
  'PLAYERS_LIST_TITLE': 'Players',
  'ALERT_PLAYERS_LIST_TITLE': 'Alert email players offline ',
  'ALERT_PLAYERS_TEST_MESSAGE_SEND': 'The offline players alert test is being sent by email.',
  'PLAYER_REPORT_OFFLINE': 'Player report offline ',
  'PLAYERS_DECONNECTED_CREATE': 'Alert email (Player offline)',
  'PLAYERS_EXPORT_TO_EXCEL': 'File',
  'PLAYERS_EXPORT_TO_EXCEL_BY_EMAIL': 'Email',
  'PLAYER_TYPE': 'Service',
  'PLAYER_SITE': 'Subscription',
  'PLAYER_ID': 'Player ID',
  'PLAYER_STATUS': 'Status',
  'PLAYER_DEFAULT': 'Default',
  'PLAYER_PRODUCT': 'Product',
  'PLAYER_COUNTRY': 'Country',
  'PLAYER_LAST_CONNECTION': 'Last Connection',
  'PLAYER_EXTERNAL_ID': 'External Id',
  'PLAYER_ONLINE': 'Online',
  'PLAYER_OFFLINE': 'Offline',
  'PLAYER_UPDATE_FAILED': 'Update failed',
  'PLAYER_UPDATE_INCOMPLETE': 'Partially up-to-date',
  'PLAYER_UPDATE_SUCCESS': 'Up-to-date',
  'PLAYER_DELIVERED': 'Delivered',
  'PLAYER_NOT_DELIVERED': 'In transit',
  'PLAYERS_LIST_FILTER': 'Filter',
  'PLAYERS_LIST_FILTER_SITE': 'Site',
  'PLAYERS_LIST_FILTER_SUBSCRIPTION': 'Subscription',
  'PLAYERS_LIST_FILTER_PLAYER_ID': 'Player ID',
  'PLAYERS_LIST_FILTER_PLAYER_TYPE': 'Service',
  'PLAYERS_LIST_FILTER_SERVICE_DEFAULT': ' ',
  'PLAYERS_LIST_FILTER_SERVICE_AUDIO': 'Audio players only',
  'PLAYERS_LIST_FILTER_SERVICE_VIDEO': 'Video players only',
  'PLAYERS_LIST_FILTER_PRODUCT': 'Product',
  'PLAYERS_LIST_FILTER_DEFAULT': 'Default?',
  'PLAYERS_LIST_FILTER_DEFAULT_DEFAULT': ' ',
  'PLAYERS_LIST_FILTER_DEFAULT_YES': 'Default players only',
  'PLAYERS_LIST_FILTER_DEFAULT_NO': 'Other players',
  'PLAYERS_LIST_FILTER_SEARCH': 'Search',
  'PLAYERS_LIST_FILTER_RESET': 'Reset',
  'COUNTRIES_LOAD_ERROR': 'An error occurred while loading available countries.',
  'PLAYERS_LOAD_ERROR': 'An error occurred while loading players.',
  'PLAYERS_LOAD_BAD_REQUEST_ERROR': 'A validation error occurred while loading players.',
  'PLAYERS_LIST_FILTER_COUNTRY_DEFAULT': ' ',
  'PLAYERS_LIST_FILTER_COUNTRY': 'Country',
  'PLAYERS_LIST_FILTER_UPDATED': 'Up-to-date?',
  'PLAYERS_LIST_FILTER_UPDATED_YES': 'Up-to-date players',
  'PLAYERS_LIST_FILTER_UPDATED_INCOMPLETE': 'Partially updated players',
  'PLAYERS_LIST_FILTER_UPDATED_FAILED': 'Updated failed players',
  'PLAYERS_LIST_FILTER_UPDATED_DEFAULT': ' ',
  'PLAYERS_LIST_FILTER_ONLINE': 'Online?',
  'PLAYERS_LIST_FILTER_ONLINE_YES': 'Online players',
  'PLAYERS_LIST_FILTER_ONLINE_NO': 'Offline players',
  'PLAYERS_LIST_FILTER_ONLINE_DEFAULT': ' ',
  'PLAYERS_LIST_FILTER_EXTERNAL_ID': 'External Id',
  'PLAYERS_LIST_QUICK_SEARCH': 'Quick search by player id or site',
  'PLAYERS_LIST_ISSUE_QUICK_SEARCH': 'Quick search by subscription',
  'PRODUCTS_LOAD_ERROR': 'An error occurred while loading available products.',
  'PLAYERS_LIST_FILTER_PRODUCT_DEFAULT': ' ',
  'PLAYER_INFO_TITLE': 'Player Info',
  'PLAYER_GENERAL': 'General',
  'PLAYER_LOCATION': 'Location',
  'PLAYER_SHIPPING': 'Shipping Info',
  'PLAYER_CONNECTIVITY': 'Connectivity',
  'PLAYER_MODEL': 'Model',
  'PLAYER_DEVICE_ID': 'Device ID',
  'PLAYER_TIMEZONE': 'Timezone',
  'PLAYER_SITE_STATUS': 'Site Status',
  'PLAYER_IP_ADDRESS': 'Local IP',
  'PLAYER_FIRMWARE': 'Firmware',
  'PLAYER_SERIAL_NUMBER': 'Serial Number',
  'PLAYER_DELIVERY_STATUS': 'Delivery Status',
  'PLAYER_UPDATED': 'Updated',
  'PLAYER_MAC_ADDRESS': 'MAC Address',
  'PLAYER_WIFI_MAC_ADDRESS': 'Wifi MAC Address',
  'PLAYER_LAST_UPDATE': 'Last Update',
  'PLAYER_LAST_DOWNLOAD': 'Last Download',
  'PLAYER_LAST_CONFIG': 'Last Config',
  'PLAYER_LAST_TASK': 'Last Task',
  'PLAYER_LAST_MONITORING': 'Last Monitoring',
  'PLAYER_LAST_PLAY': 'Last Play',
  'PLAYER_PUBLIC_IP': 'Public IP',
  'EDIT_ALERT_MESSAGE_GERMAN': 'German',
  'PLAYER_SITE_STATUS_STOP': 'Stopped',
  'PLAYER_SITE_STATUS_TO_OFF': 'To Off',
  'PLAYER_SITE_STATUS_OFF': 'Off',
  'PLAYER_SITE_STATUS_DEMO': 'Pilot',
  'PLAYER_SITE_STATUS_EXPLOIT': 'Exploit',
  'PLAYER_GENERAL_INFO': 'General Info',
  'PLAYER_MONITORING_INFO': 'Monitoring Info',
  'PLAYER_INVENTORY': 'Inventory',
  'OPENING_HOURS': 'Opening hours',
  'PLAYER_INVENTORY_PAGE_TITLE': 'Portal | Players | Inventory',
  'REFRESH_PLAYER': 'Click here to reload the player info',
  'GEOCODE_OVER_QUERY_LIMIT': 'Too many geocoding requests have been made to the Google maps API.',
  'GEOCODE_GENERIC_ERROR': 'An error occurred while geocoding the address with the Google maps API.',
  'GEOCODE_ZERO_RESULTS': 'The address couldn\'t be geocoded with the Google maps API.',
  'PLAYERS_DOWNLOAD_CSV_ERROR': 'An error occurred while downloading all the players as CSV.',
  'PLAYERS_DOWNLOAD_CSV_START': 'The excel file containing all the players information is currently generated and will be downloaded as soon as possible',
  'PLAYERS_SEND_BY_EMAIL_CSV_ERROR': 'An error occurred while sending by email all the players as CSV.',
  'PLAYERS_SEND_BY_EMAIL_CSV_START': 'The excel file containing all the players information is currently send on your email',
  'PLAYER_INFO_ERROR': 'An error occurred while loading the player information.',
  'PLAYER_SHIPPING_ERROR': 'An error occurred while loading the player shipping information.',
  'PLAYER_SHIPPING_TYPE': 'Type',
  'PLAYER_SHIPPING_CARRIER': 'Carrier',
  'PLAYER_SHIPPING_TRACKING': 'Tracking',
  'PLAYER_SHIPPING_PICKUP_DATE': 'Pickup Date',
  'PLAYER_SHIPPING_DELIVERY_DATE': 'Delivery Date',
  'PLAYER_SHIPPING_DELIVERY_STATUS': 'Delivery Status',
  'PLAYER_CONNECTIVITY_ERROR': 'An error occurred while loading player connectivity stats.',
  'PLAYER_LIVE_CONNECTIVITY': 'Live connection quality',
  'PLAYER_HISTORY_CONNECTIVITY': 'Connection quality history',
  'PLAYER_CONNECTIVITY_LAST_UPDATE': 'Last updated:',
  'AUTH_LOGIN_PAGE_TITLE': 'Portal | Login',
  'AUTH_LOST_PASSWORD_PAGE_TITLE': 'Portal | Lost Password',
  'AUTH_RESET_PASSWORD_PAGE_TITLE': 'Portal | Reset Password',
  'DASHBOARD_PAGE_TITLE': 'Portal | Dashboard',
  'ERROR_ACCESS_DENIED_PAGE_TITLE': 'Portal | Access Denied',
  'ERROR_NOT_FOUND_PAGE_TITLE': 'Portal | Not Found',
  'PLAYERS_LIST_PAGE_TITLE': 'Portal | Players',
  'PLAYERS_DETAILS_INFO_PAGE_TITLE': 'Portal | Players | Details | Player Info',
  'PLAYERS_DETAILS_PLANNING_TITLE': 'Portal | Players | Details | Planning',
  'PLAYERS_DETAILS_PLAYLIST_TITLE': 'Portal | Players | Details | Playlist',
  'PLAYERS_DETAILS_PLAY_LOGS_TITLE': 'Portal | Players | Details | Play Logs',
  'PLAYERS_DETAILS_EVENT_LOGS_TITLE': 'Portal | Players | Details | Event Logs',
  'PLAYERS_DETAILS_SCHEDULED_MESSAGES_TITLE': 'Portal | Players | Details | Scheduled Messages',
  'USER_PAGE_TITLE': 'Portal | My Profile',
  'USERS_LIST_PAGE_TITLE': 'Portal | Users',
  'USERS_LIST_QUICK_SEARCH': 'Search by name',
  'USERS_DETAILS_INFO_PAGE_TITLE': 'Portal | Users | Details | User Info',
  'USERS_DETAILS_SUBSCRIPTIONS_PAGE_TITLE': 'Portal | Users | Details | Subscriptions Info',
  'USERS_DETAILS_AUTH0_PAGE_TITLE': 'Portal | Users | Details | Connections Info',
  'ANONYMOUS_USERNAME': 'Anonymous',
  'WARNING': 'Warning!',
  'USER_SUBSCRIPTIONS_IMPORT_WARNING':
    'Importing subscriptions assignment from another user replaces the assignment of the current user. It\'s IMPOSSIBLE to cancel this action. This functionality is to be used with full knowledge of the facts.',
  'IMAGE_UPLOAD_INVALID_EXTENSION': 'You try to upload an image type that is not allowed. Only JPEG and PNG files are allowed.',
  'IMAGE_UPLOAD_PROCESSING_ERROR': 'An error occurred while processing the image.',
  'IMAGE_UPLOAD_GENERIC_ERROR': 'An error occurred in the image upload component.',
  'PLAYER_AUDIO_UPDATE_SERVER': 'Update Server',
  'PLAYER_AUDIO_PLANNING_TITLE': 'Planning',
  'PLAYER_AUDIO_PLAYLIST_TITLE': 'Playlist',
  'PLAYER_PLAY_LOGS_TITLE': 'Play Logs',
  'PLAYER_EVENT_LOGS_TITLE': 'Event Logs',
  'PLAYER_EVENT_LOGS_LAST_ERROR': 'Errors found today',
  'PLAYER_AUDIO_SCHEDULED_MESSAGES_TITLE': 'Scheduled Messages',
  'PLAYER_VIDEO_PLAYLIST_TITLE': 'Playlist',
  'AUDIO_PROGRAMS_LOAD_ERROR': 'An error occurred while loading available audio programs.',
  'AUDIO_PLANNINGS_LOAD_ERROR': 'An error occurred while loading the audio plannings.',
  'AUDIO_PLANNINGS_FILTER': 'Filter',
  'AUDIO_PLANNINGS_FILTER_PROGRAM': 'Program',
  'AUDIO_PLANNINGS_FILTER_DAY': 'Day',
  'AUDIO_PLANNINGS_FILTER_SEARCH': 'Search',
  'AUDIO_PLANNINGS_FILTER_RESET': 'Reset',
  'AUDIO_PLANNINGS_START': 'Start',
  'AUDIO_PLANNINGS_NAME': 'Name',
  'AUDIO_PLANNINGS_DURATION': 'Duration',
  'AUDIO_PLANNINGS_TYPE': 'Type',
  'AUDIO_PLANNINGS_PLAY': 'Play',
  'MEDIA_AUDIO_MUSIC': 'Music',
  'MEDIA_AUDIO_JINGLE': 'Jingle',
  'MEDIA_AUDIO_SEQUENCE': 'Sequence',
  'MEDIA_AUDIO_COMMERCIAL': 'Ads',
  'MEDIA_AUDIO_PAUSE': 'Pause',
  'PLAY_LOGS_FILTER': 'Filter',
  'PLAY_LOGS_FILTER_SEARCH': 'Search',
  'PLAY_LOGS_FILTER_RESET': 'Reset',
  'PLAY_LOGS_FILTER_FILENAME': 'File Name',
  'PLAY_LOGS_FILTER_START_DATE': 'Form (date)',
  'PLAY_LOGS_FILTER_START_TIME': 'Form (time)',
  'PLAY_LOGS_FILTER_END_DATE': 'To (date)',
  'PLAY_LOGS_FILTER_END_TIME': 'To (time)',
  'PLAYER_PLAY_LOGS_START_TIME': 'Start Time',
  'PLAYER_PLAY_LOGS_END_TIME': 'End Time',
  'PLAYER_PLAY_LOGS_FILENAME': 'File Name',
  'PLAYER_PLAY_LOGS_INFO': 'Info',
  'PLAY_LOGS_LOAD_ERROR': 'An error occurred while loading the play logs for the player.',
  'EVENT_LOGS_LOAD_ERROR': 'An error occurred while loading the event logs for the player.',
  'EVENT_LOGS_FILTER': 'Filter',
  'EVENT_LOGS_FILTER_START_DATE': 'From',
  'EVENT_LOGS_FILTER_END_DATE': 'To',
  'EVENT_LOGS_FILTER_SEARCH': 'Search',
  'EVENT_LOGS_FILTER_RESET': 'Reset',
  'EVENT_LOGS_TYPE': 'Type',
  'EVENT_LOGS_NAME': 'Name',
  'EVENT_LOGS_SOURCE': 'Source',
  'EVENT_LOGS_TIME': 'Time',
  'AUDIO_PLAYLISTS_LOAD_ERROR': 'An error occurred while loading the audio playlists.',
  'AUDIO_PLAYLISTS_LOAD_EMPTY': 'No data found in this audio playlists.',
  'AUDIO_SCHEDULED_MESSAGES_LOAD_ERROR': 'An error occurred while loading the audio scheduled messages.',
  'AUDIO_SCHEDULED_MESSAGES_FILTER': 'Filter',
  'AUDIO_SCHEDULED_MESSAGES_FILTER_DAY': 'Day',
  'AUDIO_SCHEDULED_MESSAGES_FILTER_SEARCH': 'Search',
  'AUDIO_SCHEDULED_MESSAGES_FILTER_RESET': 'Reset',
  'AUDIO_SCHEDULED_MESSAGES_ANNOUNCEMENT': 'Type',
  'AUDIO_SCHEDULED_MESSAGES_CAMPAIGN': 'Campaign',
  'AUDIO_SCHEDULED_MESSAGE_START_DATE': 'From (date)',
  'AUDIO_SCHEDULED_MESSAGE_END_DATE': 'To (date)',
  'AUDIO_SCHEDULED_MESSAGE_START_TIME': 'From (time)',
  'AUDIO_SCHEDULED_MESSAGE_END_TIME': 'To (time)',
  'AUDIO_SCHEDULED_MESSAGE_MONDAY': 'Mon.',
  'AUDIO_SCHEDULED_MESSAGE_TUESDAY': 'Tue.',
  'AUDIO_SCHEDULED_MESSAGE_WEDNESDAY': 'Wed.',
  'AUDIO_SCHEDULED_MESSAGE_THURSDAY': 'Thu.',
  'AUDIO_SCHEDULED_MESSAGE_FRIDAY': 'Fri.',
  'AUDIO_SCHEDULED_MESSAGE_SATURDAY': 'Sat.',
  'AUDIO_SCHEDULED_MESSAGE_SUNDAY': 'Sun.',
  'AUDIO_SCHEDULED_MESSAGE_MEDIA_NAME': 'Name',
  'AUDIO_SCHEDULED_MESSAGE_MEDIA_DURATION': 'Duration',
  'AUDIO_SCHEDULED_MESSAGE_MEDIA_PLAY': 'Play',
  'AUDIO_PLAYER_NOT_SUPPORTED': 'Your browser does not support the audio element.',
  'VIDEO_PLAYER_NOT_SUPPORTED': 'Your browser does not support the video element.',
  'VIDEO_PLAYLISTS_LOAD_ERROR': 'An error occurred while loading the video media.',
  'VIDEO_PLAYLISTS_TYPE': 'Type',
  'VIDEO_PLAYLISTS_THUMBNAIL': 'Thumbnail',
  'VIDEO_PLAYLISTS_MEDIA_NAME': 'Name',
  'VIDEO_PLAYLISTS_MEDIA_DURATION': 'Duration',
  'VIDEO_PLAYLISTS_SOURCE': 'Source',
  'VIDEO_PLAYLISTS_PROGRAM_MODE': 'Program Mode',
  'VIDEO_PLAYLISTS_PROGRAM_NAME': 'Program Name',
  'VIDEO_PLAYLISTS_MEDIA_RESOLUTION': 'Resolution',
  'VIDEO_PLAYLISTS_MEDIA_ROTATION': 'Rotation',
  'VIDEO_PLAYLISTS_PLAY': 'Play',
  'VIDEO_PLAYER': 'Video Player',
  'VIDEO_PLAYLISTS_FILTER': 'Filter',
  'VIDEO_PLAYLISTS_FILTER_DAY': 'Day',
  'VIDEO_PLAYLISTS_FILTER_SEARCH': 'Search',
  'VIDEO_PLAYLISTS_FILTER_RESET': 'Reset',
  'PLAYER_LOGS': 'Logs on Server',
  'SOCIAL_LOGIN_INTRO': 'Or login with',
  'LOGIN_TITLE': 'Sign In',
  'AUDIO_PLAYLIST_ADN': 'Playlist ADN',
  'AUDIO_PLAYLIST_SHOW_ADN': 'Show ADN',
  'AUDIO_PLAYLIST_STATS_LOAD_ERROR': 'An error occurred while loading the audio playlist stats.',
  'AUDIO_PLAYLIST_ADN_TITLE': 'Playlist ADN for {{subscriptionName}}',
  'AUDIO_PLAYLIST_ADN_PROGRAM': 'Program: {{programName}}',
  'AUDIO_PLAYLIST_ADN_DATE': 'Date: {{date}}',
  'AUDIO_PLAYLIST_ADN_STYLES': 'Styles',
  'AUDIO_PLAYLIST_ADN_THEMES': 'Themes',
  'AUDIO_PLAYLIST_ADN_MOOD': 'Mood',
  'AUDIO_PLAYLIST_ADN_GENDERS': 'Genders',
  'AUDIO_PLAYLIST_ADN_LANGUAGES': 'Languages',
  'AUDIO_PLAYLIST_ADN_YEARS': 'Years',
  'AUDIO_PLAYLIST_ADN_ENERGY': 'Energy',
  'AUDIO_PLAYLIST_ADN_TEMPO': 'Tempo',
  'AUDIO_PLAYLIST_ADN_BPM': 'BPM',
  'DEFAULT_TITLE': 'Portal',
  'PLAYER_QUICK_SEARCH_PLACEHOLDER': 'Player Quick Search',
  'QUICK_SEARCH_BAD_REQUEST_ERROR': 'A validation error occurred during the search.',
  'QUICK_SEARCH_LOAD_ERROR': 'An error occurred while search for a player.',
  'QUICK_SEARCH_DIALOG': 'Quick Search Results Dialog',
  'QUICK_SEARCH_DIALOG_TITLE': 'Quick Search Results',
  'PLAYER_CLIENT': 'Client',
  'GOTO_PLAYER': 'Go to player',
  'LINK_EXTERNAL_IDENTITY': 'Link External Identity',
  'ADMIN_ALERTS_LIST_TITLE': 'Portal | Admin | Alerts',
  'ADMIN': 'Admin',
  'ADMIN_ALERTS': 'Alerts',
  'QUICK_SEARCH_NO_RESULT': 'Your search yielded no result.',
  'ALERT_MESSAGES_LOAD_ERROR': 'An error occurred while loading the alert messages.',
  'ALERT_MESSAGES_LIST_TITLE': 'Alerts',
  'ALERT_MESSAGES_START': 'From',
  'ALERT_MESSAGES_END': 'To',
  'ALERT_MESSAGES_MESSAGE': 'Message',
  'ALERT_MESSAGES_AUDIENCE': 'Audience',
  'ALERT_MESSAGES_ACTIONS': 'Actions',
  'ALERT_MESSAGES_AUDIENCE_ANY': 'Any',
  'ALERT_MESSAGES_AUDIENCE_STOREVER': 'MCube Only',
  'ALERT_MESSAGES_AUDIENCE_NOTSTOREVER': 'External Only',
  'ALERT_MESSAGES_EDIT': 'Edit',
  'ALERT_MESSAGES_DELETE': 'Delete',
  'ALERT_MESSAGES_CREATE': 'Create',
  'ALERT_MESSAGES_EDIT_DIALOG_TITLE': 'Edit Alert Message',
  'ALERT_MESSAGES_CREATE_DIALOG_TITLE': 'Create Alert Message',
  'EDIT_ALERT_MESSAGE_FRENCH': 'French',
  'EDIT_ALERT_MESSAGE_DUTCH': 'Dutch',
  'EDIT_ALERT_MESSAGE_ENGLISH': 'English',
  'EDIT_ALERT_MESSAGE_SAVE': 'Save',
  'EDIT_ALERT_MESSAGE_START_DATE': 'Start date',
  'EDIT_ALERT_MESSAGE_END_DATE': 'End date',
  'EDIT_ALERT_MESSAGE_PRODUCTS': 'Affected Product(s)',
  'EDIT_ALERT_MESSAGE_AUDIENCE': 'Display audience',
  'EDIT_ALERT_MESSAGE_FRENCH_REQUIRED': 'The french message is required.',
  'EDIT_ALERT_MESSAGE_DUTCH_REQUIRED': 'The dutch message is required.',
  'EDIT_ALERT_MESSAGE_ENGLISH_REQUIRED': 'The english message is required.',
  'EDIT_ALERT_MESSAGE_FRENCH_MAX_LENGTH': 'The french message cannot be longer than 500 characters.',
  'EDIT_ALERT_MESSAGE_DUTCH_MAX_LENGTH': 'The dutch message cannot be longer than 500 characters.',
  'EDIT_ALERT_MESSAGE_ENGLISH_MAX_LENGTH': 'The english message cannot be longer than 500 characters.',
  'EDIT_ALERT_MESSAGE_BROADCAST_REQUIRED': 'The audience is required.',
  'EDIT_ALERT_MESSAGE_PRODUCTS_RESET': 'Reset',
  'CREATE_ALERT_MESSAGE_SUCCESS': 'The alert message has been created successfully.',
  'CREATE_ALERT_MESSAGE_VALIDATION_ERROR': 'A validation error occurred while creating the alert message.',
  'CREATE_ALERT_MESSAGE_ERROR': 'An error occurred while creating the alert message.',
  'UPDATE_ALERT_MESSAGE_SUCCESS': 'The alert message has been updated successfully.',
  'UPDATE_ALERT_MESSAGE_VALIDATION_ERROR': 'A validation error occurred while updating the alert message.',
  'UPDATE_ALERT_MESSAGE_ERROR': 'An error occurred while updating the alert message.',
  'CONFIRM_DELETE_ALERT_MESSAGE_DIALOG': 'Delete Alert Message Confirmation Dialog',
  'CONFIRM_DELETE_ALERT_MESSAGE_DIALOG_TITLE': 'Delete Confirmation',
  'CONFIRM_DELETE_ALERT_MESSAGE_DIALOG_ACCEPT': 'Delete',
  'CONFIRM_DELETE_ALERT_MESSAGE_MESSAGE': 'Are you sure you want to delete this alert message?',
  'DELETE_ALERT_MESSAGE_SUCCESS': 'The alert message has been deleted successfully.',
  'DELETE_ALERT_MESSAGE_ERROR': 'An error occurred while deleting the alert message.',
  'CURRENT_ALERT_MESSAGES_ERROR': 'An error occurred while loading current alert messages.',
  'ADMIN_ROLES_MANAGER_TITLE': 'Portal | Admin | Roles Manager',
  'ADMIN_ROLES_MANAGER': 'Roles Manager',
  'ROLES_MANAGER_TITLE': 'Roles Manager',
  'ROLES_MANAGER_GROUP_ROLES': 'Roles Group',
  'ROLES_MANAGER_ADD_GROUP': 'Add',
  'ROLES_MANAGER_REMOVE_GROUP': 'Delete',
  'ROLES_MANAGER_SELECT_GROUP': '--- Select a Roles Group ---',
  'GROUP_ROLE_EX_LOAD_ERROR': 'An error occurred while loading the group role details.',
  'ADMIN_GROUP_ROLE_NAME': 'Name',
  'ADMIN_GROUP_ROLE_SAVE': 'Save',
  'CREATE_GROUP_ROLES_DIALOG': 'Create a new group roles dialog',
  'CREATE_GROUP_ROLES_TITLE': 'Create a Group Roles',
  'CREATE_GROUP_ROLES_BUTTON': 'Create',
  'GROUP_ROLES_NAME_TEMPLATE': 'Select a existing Template.',
  'GROUP_ROLES_NAME': 'Create a new group role.',
  'GROUP_ROLES_NAME_REQUIRED': 'The name of the group roles is required.',
  'GROUP_ROLES_NAME_MIN_LENGTH': 'The name of the group roles must be at least 3 characters long.',
  'GROUP_ROLES_NAME_MAX_LENGTH': 'The name of the group roles cannot be longer than 50 characters.',
  'CONFIRM_DELETE_GROUP_ROLES_DIALOG': 'Delete Group Roles Confirmation Dialog',
  'CONFIRM_DELETE_GROUP_ROLES_DIALOG_TITLE': 'Delete Confirmation',
  'CONFIRM_DELETE_EMPTY_GROUP_ROLES_MESSAGE': 'Are you sure you want to delete the "{{name}}" group roles?',
  'CONFIRM_DELETE_GROUP_ROLES_DIALOG_ACCEPT': 'Delete',
  'CREATE_GROUP_ROLES_SUCCESS': 'The group roles has been created successfully.',
  'CREATE_GROUP_ROLES_VALIDATION_ERROR': 'A validation error occurred while creating the group roles.',
  'CREATE_GROUP_ROLES_ERROR': 'An error occurred while creating the group roles.',
  'UPDATE_GROUP_ROLES_SUCCESS': 'The group roles has been updated successfully.',
  'UPDATE_GROUP_ROLES_VALIDATION_ERROR': 'A validation error occurred while updating the group roles.',
  'UPDATE_GROUP_ROLES_ERROR': 'An error occurred while updating the group roles.',
  'DELETE_GROUP_ROLES_SUCCESS': 'The group roles has been deleted successfully.',
  'DELETE_GROUP_ROLES_ERROR': 'An error occurred while deleting the group roles.',
  'EXTERNAL_APPS': 'More Apps',
  'ROLE_VIEW': 'Can access the application',
  'ROLE_PORTAL_ACTION': 'Can perform actions',
  'ROLE_PORTAL_EVENTLOG': 'Can view event logs',
  'ROLE_PORTAL_PLAYLOG': 'Can view play logs',
  'ROLE_PORTAL_PLANNING': 'Can view plannings',
  'ROLE_PORTAL_TICKET': 'Can view tickets',
  'ROLE_RUNAS': 'Can impersonate someone else',
  'ROLE_PORTAL_USERMGR': 'Can manage users',
  'ROLE_PORTAL_STOREVER': 'Has access to MCube views',
  'ROLE_PORTAL_EXPERT': 'Has access to advanced views',
  'ROLE_PORTAL_PUBLISH': 'Can publish to a player',
  'ROLE_PORTAL_REBOOT': 'Can reboot a player',
  'ROLE_PORTAL_SRVLOG': 'Can view server logs',
  'ROLE_DS_MEDIA': 'Can access the media management',
  'ROLE_DS_UPLOAD': 'Can upload new media',
  'ROLE_DS_EXPERT': 'Has access to advanced views and actions',
  'ROLE_CREATOR_UPLOAD_IMG': 'Can upload images',
  'ROLE_CREATOR_UPLOAD_SHAPE': 'Can upload shapes',
  'ROLE_CREATOR_TEMPLATE_NEW': 'Can create new templates',
  'ROLE_CREATOR_TEMPLATE_SHARE': 'Can share templates',
  'ROLE_DS_SHARE': 'Can share media/playlist/programs',
  'ROLE_DS_ROTATE_MEDIA': 'Is allowed to rotate media',
  'ROLE_CREATOR_EXPERT': 'Has access to advanced views and actions',
  'ROLE_APS_PROGRAM': 'Can manage programs',
  'SITE_TITLE': 'Portal',
  'ROLE_DS_PUBLISH': 'Can publish to players',
  'COMMANDS_LOAD_ERROR': 'An error occurred while loading the player\'s commands.',
  'PLAYER_COMMANDS_TITLE': 'Sent Commands',
  'PLAYER_COMMANDS_PAGE_TITLE': 'Portal | Players | Details | Commands',
  'COMMANDS_FILTER': 'Filter',
  'COMMANDS_FILTER_DAY': 'Day',
  'COMMANDS_FILTER_SEARCH': 'Search',
  'COMMANDS_FILTER_RESET': 'Reset',
  'COMMANDS_TEXT': 'Command',
  'COMMANDS_NAME': 'Name',
  'COMMANDS_USER': 'Creator',
  'COMMANDS_SEND_DATE': 'Sent',
  'COMMANDS_RESULT_DATE': 'Answered',
  'COMMAND_CUSTOM': 'Custom...',
  'COMMAND_CTEST': 'Connection Test',
  'COMMAND_DIR': 'List content',
  'COMMAND_CURCHAN': 'Current Channel',
  'COMMAND_KCHK': 'Check Decryption Key',
  'COMMAND_CHKDSK': 'Check Disk',
  'COMMAND_ISP': 'Get Config',
  'COMMAND_STASK': 'Get Tasks List',
  'COMMAND_DC': 'Disconnect',
  'COMMAND_PP_1': 'Play Playlist 1',
  'COMMAND_PS_1': 'Play Station 1',
  'COMMAND_POWER_ON': 'Power On',
  'COMMAND_POWER_OFF': 'Power Off',
  'COMMAND_RANDOM_ON': 'Random On',
  'COMMAND_RANDOM_OFF': 'Random Off',
  'COMMAND_STATUS': 'Status',
  'COMMAND_TCP_FAST': 'TCP Fast',
  'COMMAND_TCP_NORMAL': 'TCP Normal',
  'COMMAND_TCP_ULTRAFAST': 'TCP Ultra Fast',
  'COMMAND_RESTART': 'Restart',
  'COMMAND_SAF': 'Get Update URL',
  'COMMAND_SDTEST': 'Test SD-Card',
  'COMMAND_UPDATE': 'Update',
  'COMMAND_REBOOT': 'Reboot',
  'COMMAND_UPGRADE FIRMWARE': 'Upgrade firmware',
  'COMMAND_TAKE SCREENSHOT': 'Take screenshot',
  'PLAYER_SCREENSHOT_TITLE': 'Screenshot',
  'PLAYER_SCREENSHOT_REPORTED_AT': 'Reported at',
  'PLAYER_SCREENSHOT_DIMENSION': 'Dimension',
  'PLAYER_SCREENSHOT_SIZE': 'Size',
  'PLAYER_SCREENSHOT_FORMAT': 'Format',
  'COMMAND_SNUM': 'Get Serial Number',
  'COMMAND_CHANNEL': 'Get Channels List',
  'COMMAND_SYSTAT': 'Get System Status',
  'COMMAND_TIME': 'Get Time',
  'COMMAND_UCP': 'Update Commercial Plan',
  'COMMAND_UCA': 'Update Commercials',
  'COMMAND_UP': 'Update',
  'COMMAND_IDINBG_0': 'Disable Update in Background',
  'COMMAND_IDINBG_1': 'Enable Update in Background',
  'COMMAND_IDINBG_OFF': 'Disable Update in Background',
  'COMMAND_IDINBG_ON': 'Enable Update in Background',
  'COMMAND_UD': 'Update Config',
  'COMMAND_UO': 'Update Playlist Only',
  'COMMAND_UF': 'Update Firmware',
  'COMMAND_US': 'Update Schedule',
  'COMMAND_VOLUME_MAX': 'Set Volume Max',
  'SEND_COMMAND_SUCCESS': 'The command has been queued to be sent to the player.',
  'SEND_COMMAND_FAIL': 'An error occurred while queueing the command.',
  'CUSTOM_COMMAND_DIALOG': 'Custom player command dialog',
  'CUSTOM_COMMAND_DIALOG_TITLE': 'Custom Command',
  'SEND_COMMAND_BUTTON': 'Send',
  'CUSTOM_COMMAND_LABEL': 'Enter the command to send to the player:',
  'CUSTOM_COMMAND_REQUIRED': 'The command is required.',
  'DASHBOARD_ADVANCED_COUNTERS_ERROR': 'An error occurred while loading the advanced counters.',
  'DASHBOARD_COUNTERS_ERROR': 'An error occurred while loading the counters.',
  'RELOAD': 'Reload',
  'LOADING': 'Loading...',
  'DASHBOARD_COUNTERS_TITLE_LOADING': 'Players Stats',
  'DASHBOARD_COUNTERS_TITLE_LOADED': 'Players Stats ({{count}} total player(s))',
  'DASHBOARD_COUNTERS_PLAYER_TYPE_CHART': 'Player Types',
  'DASHBOARD_COUNTERS_PLAYER_AUDIO_CHART': 'Audio Player',
  'DASHBOARD_COUNTERS_PLAYER_VIDEO_CHART': 'Video Player',
  'DASHBOARD_COUNTERS_PLAYER_ONLINE_STATUS_CHART': 'Online Status',
  'DASHBOARD_COUNTERS_PLAYER_ONLINE_CHART': 'Online',
  'DASHBOARD_COUNTERS_PLAYER_OFFLINE_CHART': 'Offline',
  'DASHBOARD_COUNTERS_PLAYER_UPDATE_STATUS_CHART': 'Update Status',
  'DASHBOARD_COUNTERS_PLAYER_UPDATED_CHART': 'Updated',
  'DASHBOARD_COUNTERS_PLAYER_NOT_UPDATED_CHART': 'Not Updated',
  'DASHBOARD_COUNTERS_PLAYER_UPDATE_INCOMPLETE_CHART': 'Partially Updated',
  'DASHBOARD_COUNTERS_POINT_FORMAT_PART1': 'Number of player(s): ',
  'DASHBOARD_COUNTERS_POINT_FORMAT_PART2': 'Percentage of player(s): ',
  'PLAYER_CONNECTIVITY_STATS_ERROR': 'An error occurred while loading the player connectivity stats.',
  'DASHBOARD_CONNECTIVITY_STATS_ERROR': 'An error occurred while loading the client connectivity stats.',
  'DASHBOARD_CONNECTIVITY_TITLE': 'Connectivity Stats',
  'DASHBOARD_UPDATE_TITLE': 'Update Stats',
  'DASHBOARD_CONNECTIVITY_CHART_TITLE': 'All players connectivity',
  'DASHBOARD_CONNECTIVITY_CHART_SUBTITLE': 'Click and drag in the plot area to zoom in',
  'DASHBOARD_CONNECTIVITY_CHART_YAXIS': 'Connectivity Quality',
  'DASHBOARD_UPDATE_CHART_TITLE': 'All players update',
  'DASHBOARD_UPDATE_CHART_SUBTITLE': 'Click and drag in the plot area to zoom in',
  'DASHBOARD_UPDATE_CHART_YAXIS': 'Update Quality',
  'DASHBOARD_SHIPPING_ERROR': 'An error occurred while loading the client shipping list.',
  'DASHBOARD_SHIPPING_INFO_TITLE': 'Shipping Info',
  'DASHBOARD_SHIPPING_NAME': 'Name',
  'DASHBOARD_SHIPPING_TYPE': 'Type',
  'DASHBOARD_SHIPPING_CARRIER': 'Carrier',
  'DASHBOARD_SHIPPING_TRACKING': 'Tracking',
  'DASHBOARD_SHIPPING_PICKUP_DATE': 'Pickup Date',
  'DASHBOARD_SHIPPING_DELIVERY_DATE': 'Delivery Date',
  'DASHBOARD_SHIPPING_DELIVERY_STATUS': 'Delivery Status',
  'DASHBOARD_SHIPPING_NO_DATA': 'There is no data to show here.',
  'DASHBOARD_ACTIVITY_FEED_TITLE': 'Activity Feed',
  'DASHBOARD_ACTIVITY_FEED_ERROR': 'An error occurred while loading the activity feed.',
  'DASHBOARD_ACTIVITY_FEED_CREATE_GROUPROLE': '{{userName}} created group roles {{name}}',
  'DASHBOARD_ACTIVITY_FEED_UPDATE_GROUPROLE': '{{userName}} updated group roles {{name}}',
  'DASHBOARD_ACTIVITY_FEED_DELETE_GROUPROLE': '{{userName}} deleted group roles {{name}}',
  'DASHBOARD_ACTIVITY_FEED_CREATE_GROUPROLES': '{{userName}} created {{count}} groups roles',
  'DASHBOARD_ACTIVITY_FEED_UPDATE_GROUPROLES': '{{userName}} updated {{count}} groups roles',
  'DASHBOARD_ACTIVITY_FEED_DELETE_GROUPROLES': '{{userName}} deleted {{count}} group roles',
  'DASHBOARD_ACTIVITY_FEED_CREATE_USER': '{{userName}} created user <a href="/users/{{uuid}}">{{name}}</a>',
  'DASHBOARD_ACTIVITY_FEED_UPDATE_USER': '{{userName}} updated user <a href="/users/{{uuid}}">{{name}}</a>',
  'DASHBOARD_ACTIVITY_FEED_DELETE_USER': '{{userName}} deleted user {{name}}',
  'DASHBOARD_ACTIVITY_FEED_CREATE_USERS': '{{userName}} created {{count}} users',
  'DASHBOARD_ACTIVITY_FEED_UPDATE_USERS': '{{userName}} updated {{count}} users',
  'DASHBOARD_ACTIVITY_FEED_DELETE_USERS': '{{userName}} deleted {{count}} users',
  'DASHBOARD_ACTIVITY_FEED_NO_DATA': 'There is no activity to show here.',
  'DASHBOARD_ACTIVITY_FEED_UPDATE_CLIENT': '{{userName}} update client',
  'DASHBOARD_ACTIVITY_FEED_UPDATE_SCHEDULE_AUDIO_COMMERCIAL': '{{userName}} schedule audio commercial',
  'DASHBOARD_ACTIVITY_FEED_DELETE_MEDIA': '{{userName}} delete media',
  'DASHBOARD_ACTIVITY_FEED_TEST_CLIENT': '{{userName}} test client',
  'DASHBOARD_LAST_SERVER_CALL_ERROR': 'An error occurred while loading the last server call.',
  'DASHBOARD_LAST_SERVER_CALL_TITLE': 'Relay Server Status',
  'DASHBOARD_LAST_SERVER_CALL_ONLINE': 'The server is online',
  'DASHBOARD_LAST_SERVER_CALL_OFFLINE': 'The server is offline',
  'DASHBOARD_LAST_SERVER_CALL_NO_DATA': 'There is no relay server status to show here.',
  'DASHBOARD_LOGMATIC_ANALYZES_TITLE': 'Logmatic Report',
  'DASHBOARD_LOGMATIC_ANALYZES_ERROR': 'An error occurred while loading the logmatic report.',
  'DASHBOARD_LOGMATIC_ANALYZES_NO_DATA': 'There is no logmatic report to show here.',
  'DASHBOARD_LOGMATIC_ANALYZES_ERROR_CODE': 'Error Code',
  'DASHBOARD_LOGMATIC_ANALYZES_ERROR_NUMBER': '# Errors',
  'PLAYERS_LIST_FILTER_DELIVERY_STATUS': 'Delivery Status',
  'PLAYERS_LIST_FILTER_DELIVERY_STATUS_DEFAULT': ' ',
  'PLAYERS_LIST_FILTER_DELIVERY_STATUS_DELIVERED': 'Delivered',
  'PLAYERS_LIST_FILTER_DELIVERY_STATUS_IN_TRANSIT': 'In Transit',
  'AUTH_LOGIN_TITLE': 'Sign In',
  'ADMIN_CLIENTS_LIST_TITLE': 'Portal | Admin | Clients',
  'ADMIN_CLIENTS': 'Clients',
  'ADMIN_CLIENTS_LIST_FILTER': 'Filter',
  'ADMIN_CLIENTS_LIST_FILTER_SEARCH': 'Search',
  'ADMIN_CLIENTS_LIST_FILTER_RESET': 'Reset',
  'ADMIN_CLIENTS_LIST_FILTER_NAME': 'Name',
  'ADMIN_CLIENTS_LIST_FILTER_OFFICE': 'Office',
  'ADMIN_CLIENTS_LIST_FILTER_SALES': 'Sales',
  'ADMIN_CLIENTS_LIST_FILTER_SERVICES': 'Services',
  'ADMIN_CLIENTS_LIST_FILTER_SERVICES_DEFAULT': ' ',
  'ADMIN_CLIENTS_LIST_FILTER_SERVICES_AUDIO': 'Audio',
  'ADMIN_CLIENTS_LIST_FILTER_SERVICES_VIDEO': 'Video',
  'ADMIN_CLIENTS_LIST_CREATE': 'Create',
  'CLIENTS_LIST_LOAD_BAD_REQUEST_ERROR': 'There is an error in the search filter.',
  'CLIENTS_LIST_LOAD_ERROR': 'An error occurred while fetching the clients list.',
  'ADMIN_CLIENTS_NAME': 'Name',
  'ADMIN_CLIENTS_CUSTOMER_ID': 'Customer ID',
  'ADMIN_CLIENTS_C_NAME': 'CName',
  'ADMIN_CLIENTS_OFFICE': 'Office',
  'ADMIN_CLIENTS_SALES': 'Salesman',
  'ADMIN_CLIENTS_SERVICES': 'Service(s)',
  'ADMIN_CLIENTS_UPDATED': 'Updated',
  'ADMIN_CLIENTS_CREATED': 'Created',
  'SALES_LOAD_ERROR': 'An error occurred while retrieving the salesmen list.',
  'ADMIN_EDIT_CLIENT': 'Portal | Admin | Edit Client',
  'ADMIN_CREATE_CLIENT': 'Portal | Admin | Create Client',
  'CLIENT_LOAD_ERROR': 'An error occurred while loading the client details.',
  'EDIT_CLIENT_TITLE': 'Edit client {{name}}',
  'CREATE_CLIENT_TITLE': 'Create new client',
  'SAVE_CLIENT_BUTTON': 'Save',
  'CREATE_CLIENT_BUTTON': 'Create',
  'CLIENT_NAME': 'Name',
  'CLIENT_OFFICE': 'Office',
  'CLIENT_SALES': 'Salesman',
  'CLIENT_SERVICES': 'Services',
  'CLIENT_SERVICE_AUDIO': 'Audio',
  'CLIENT_SERVICE_VIDEO': 'Video',
  'CLIENT_SERVICE_SERVICE': 'Service',
  'CLIENT_SERVICE_MAINTENANCE': 'Maintenance',
  'CLIENT_SERVICE_HOSTING': 'Hosting',
  'CLIENT_APPLICATIONS': 'Applications',
  'CURRENT_CLIENT_LOAD_ERROR': 'An error occurred while loading the current client info.',
  'CLIENT_SALES_ME_BUTTON': 'Me!',
  'ADMIN_CLIENT_NOT_SALESMAN': 'You are not referenced as a salesman. Please select a salesman from the drop down list.',
  'CLIENT_NAME_REQUIRED': 'The client name is required.',
  'CLIENT_NAME_MAX_LENGTH': 'The client name cannot be longer than 50 characters.',
  'CLIENT_OFFICE_REQUIRED': 'The office is required.',
  'CLIENT_SALES_REQUIRED': 'The salesman is required.',
  'CLIENT_APPS_LOAD_ERROR': 'An error occurred while loading the client applications.',
  'CREATE_CLIENT_VALIDATION_ERROR': 'A validation error occurred while creating the client.',
  'CREATE_CLIENT_ERROR': 'An error occurred while creating the client.',
  'CREATE_CLIENT_SUCCESS': 'The new client has been created successfully.',
  'UPDATE_CLIENT_VALIDATION_ERROR': 'A validation error occurred while updating the client.',
  'UPDATE_CLIENT_ERROR': 'An error occurred while updating the client.',
  'UPDATE_CLIENT_SUCCESS': 'The client has been updated successfully.',
  'AUTH_LOGIN_ERROR_TITLE': 'An error occurred',
  'AUTH_LOGIN_ERROR_PARA': 'The following error occurred while logging in:',
  'AUTH_LOGIN_GO_BACK_BUTTON': 'Go back to the login page',
  'USER_PROFILE_LOAD_ERROR': 'An error occurred while loading your user profile from the authentication provider.',
  'USER_UNBLOCKED': ' ',
  'USER_BLOCKED': 'Blocked',
  'AUTH_NEW_PASSWORD_PAGE_TITLE': 'Portal | Choose your Password',
  'AUTH_NEW_PASSWORD_TITLE': 'Choose your Password',
  'AUTH_NEW_PASSWORD_PARA': 'Enter your password and confirm it.',
  'AUTH_NEW_PASSWORD_ERROR': 'An error occurred while saving your password.',
  'AUTH_NEW_PASSWORD_SUCCESS': 'Your password has been saved.',
  'AUTH_NEW_PASSWORD_NEW': 'Password',
  'AUTH_NEW_PASSWORD_NEW_REQUIRED': 'The password is required.',
  'AUTH_NEW_PASSWORD_NEW_MINLENGTH': 'The password must contain at least 4 characters long.',
  'AUTH_NEW_PASSWORD_CONFIRM': 'Password Confirmation',
  'AUTH_NEW_PASSWORD_CONFIRM_MISMATCH': 'The password and its confirmation does not match.',
  'AUTH_NEW_PASSWORD_SUBMIT': 'Submit',
  'AUTH_NEW_PASSWORD_SUBMITTING': 'Submitting...',
  'PAGER_FIRST': 'First',
  'PAGER_PREVIOUS': 'Previous',
  'PAGER_NEXT': 'Next',
  'PAGER_LAST': 'Last',
  'USER_SUBSCRIPTIONS_FILTER': 'Filter players',
  'EDIT_ALERT_MESSAGE_START_DATE_REQUIRED': 'The start date is required.',
  'EDIT_ALERT_MESSAGE_START_DATE_INVALID': 'The start date is invalid (The format is DD/MM/YYYY HH:mm).',
  'EDIT_ALERT_MESSAGE_END_DATE_REQUIRED': 'The end date is required.',
  'EDIT_ALERT_MESSAGE_END_DATE_INVALID': 'The end date is invalid (The format is DD/MM/YYYY HH:mm).',
  'EDIT_ALERT_MESSAGE_END_DATE_NOT_AFTER_START_DATE': 'The end date must be after the start date.',
  'EDIT_ALERT_MESSAGE_START_DATE_TOO_EARLY': 'The start date cannot be in the past.',
  'COMMAND_LIST_FILTER_DAY_REQUIRED': 'The day is required.',
  'COMMAND_LIST_FILTER_DAY_INVALID': 'The day is invalid (The format is DD/MM/YYYY).',
  'DATE_TIME_PLACEHOLDER': 'DD/MM/YYYY HH:mm',
  'DATE_PLACEHOLDER': 'DD/MM/YYYY',
  'AUDIO_SCHEDULED_MESSAGES_FILTER_DAY_REQUIRED': 'The day is required.',
  'AUDIO_SCHEDULED_MESSAGES_FILTER_DAY_INVALID': 'The day is invalid (The format is DD/MM/YYYY).',
  'VIDEO_PLAYLIST_FILTER_DAY_REQUIRED': 'The day is required.',
  'VIDEO_PLAYLIST_FILTER_DAY_INVALID': 'The day is invalid (The format is DD/MM/YYYY).',
  'VIDEO_PLAYLIST_FILTER_DAY_NOT_IN_RANGE': 'The day must be between {{from}} and {{to}}.',
  'AUDIO_PLANNING_FILTER_DAY_REQUIRED': 'The day is required.',
  'AUDIO_PLANNING_FILTER_DAY_INVALID': 'The day is invalid (The format is DD/MM/YYYY).',
  'AUDIO_PLANNING_FILTER_DAY_NOT_IN_RANGE': 'The day must be between {{from}} and {{to}}.',
  'EVENT_LOGS_FILTER_START_DATE_REQUIRED': 'The "from" date is required.',
  'EVENT_LOGS_FILTER_START_DATE_INVALID': 'The "from" date is invalid (The format is DD/MM/YYYY HH:mm).',
  'EVENT_LOGS_FILTER_END_DATE_REQUIRED': 'The "to" date is required.',
  'EVENT_LOGS_FILTER_END_DATE_INVALID': 'The "to" date is invalid (The format is DD/MM/YYYY HH:mm).',
  'EVENT_LOGS_FILTER_END_DATE_NOT_AFTER_START_DATE': 'The "from" date must be before the "to" date.',
  'PLAY_LOGS_FILTER_START_DATE_REQUIRED': 'The "from" date is required.',
  'PLAY_LOGS_FILTER_START_DATE_INVALID': 'The "from" date is invalid (The format is DD/MM/YYYY HH:mm).',
  'PLAY_LOGS_FILTER_END_DATE_REQUIRED': 'The "to" date is required.',
  'PLAY_LOGS_FILTER_END_DATE_INVALID': 'The "to" date is invalid (The format is DD/MM/YYYY HH:mm).',
  'PLAY_LOGS_FILTER_END_DATE_NOT_AFTER_START_DATE': 'The "from" date must be before the "to" date.',
  'PLAY_LOGS_FILTER_EXCEEDED': 'Logs are maximum {{logs}} days time life',
  'DASHBOARD_ACTIVITY_FEED_CREATE_CLIENT': '{{userName}} created client {{name}}',
  'DATATABLE_DEFAULT_EMPTY_TEXT': 'There is no data to display here.',
  'CONFIRM_DELETE_NON_EMPTY_GROUP_ROLES_MESSAGE_ONE':
    'A User is still assigned to the "{{name}}" group roles. In which other group roles do you want to move it?',
  'CONFIRM_DELETE_NON_EMPTY_GROUP_ROLES_MESSAGE_MANY':
    '{{count}} users are still assigned to the "{{name}}" group roles. In which other group roles do you want to move them?',
  'ADMIN_CLIENTS_APPS': 'Application(s)',
  'ADMIN_CLIENT_APP_1': 'Portal',
  'ADMIN_CLIENT_APP_2': 'DSManager',
  'ADMIN_CLIENT_APP_3': 'Creator',
  'ADMIN_CLIENT_APP_4': 'AudioManager',
  'DATETIME_PICKER_TODAY': 'Go to today',
  'DATETIME_PICKER_CLEAR': 'Clear selection',
  'DATETIME_PICKER_CLOSE': 'Close the picker',
  'DATETIME_PICKER_SELECT_MONTH': 'Select Month',
  'DATETIME_PICKER_PREV_MONTH': 'Previous Month',
  'DATETIME_PICKER_NEXT_MONTH': 'Next Month',
  'DATETIME_PICKER_SELECT_YEAR': 'Select Year',
  'DATETIME_PICKER_PREV_YEAR': 'Previous Year',
  'DATETIME_PICKER_NEXT_YEAR': 'Next Year',
  'DATETIME_PICKER_SELECT_DECADE': 'Select Decade',
  'DATETIME_PICKER_PREV_DECADE': 'Previous Decade',
  'DATETIME_PICKER_NEXT_DECADE': 'Next Decade',
  'DATETIME_PICKER_PREV_CENTURY': 'Previous Century',
  'DATETIME_PICKER_NEXT_CENTURY': 'Next Century',
  'DATETIME_PICKER_INCREMENT_HOUR': 'Increment Hour',
  'DATETIME_PICKER_PICK_HOUR': 'Pick Hour',
  'DATETIME_PICKER_DECREMENT_HOUR': 'Decrement Hour',
  'DATETIME_PICKER_INCREMENT_MINUTE': 'Increment Minute',
  'DATETIME_PICKER_PICK_MINUTE': 'Pick Minute',
  'DATETIME_PICKER_DECREMENT_MINUTE': 'Decrement Minute',
  'DATETIME_PICKER_INCREMENT_SECOND': 'Increment Second',
  'DATETIME_PICKER_PICK_SECOND': 'Pick Second',
  'DATETIME_PICKER_DECREMENT_SECOND': 'Decrement Second',
  'CULTURE_COMMON': 'Common cultures',
  'CULTURE_OTHERS': 'Other cultures',
  'PLAYER_PLAY_LOGS_NAME': 'Name',
  'PLAYER_CACHE': 'Server Cache',
  'ROLE_APS_MEDIA': 'Can manage new media',
  'ROLE_APS_PUBLISH': 'Can publish to players',
  'ROLE_APS_EXPERT': 'Has access to advanced views and actions',
  'CREATE_USER_NO_GROUPS': 'There is no group roles defined for this client.',
  'EDIT_CLIENT_INFO': 'Client Info',
  'INVENTORY_LAST_MODIFICATION': 'Last modification',
  'WARNING_MESSAGE_PLANNING_UPDATE_FAIL':
    'The musical planning is proposed only as an indication. The chronology can be altered by many factors independent of the Player. For example, the addition of advertising messages cause a shift in the music schedule that may vary depending on the amount of messages to be plat over 24 hours.',
  'WARNING_MESSAGE_PLAYLIST_UPDATE_FAIL':
    'The playlist is only an indication. It can be altered by many factors independent of the Player. For example, if the daily update has failed or is incomplete, the missing audio files will not be broadcast.',
  'WARNING_TITLE_UPDATE_FAIL': 'For your information',
  'ROLE_APS_CAMPAIGN': 'Can manage the campaigns',
  'ROLE_APS_UPLOAD': 'Can add new media',
  'ROLE_APS_SHARE': 'Can share media',
  'ROLE_APS_MUSTVALIDMEDIA': 'Must validate the media',
  'ROLE_VIEW_CORPORATE': 'View all',
  'ROLE_APS_CAMPAIGN_SUBSCRIPTION': 'Can manage local campaigns',
  'ROLE_EDIT_CORPORATE': 'Edit all',
  'ROLE_PORTAL_VIEWUPDATESTATUS': 'View player\'s update status',
  'ROLE_DS_PLAYLIST': 'Can manage the playlists',
  'ROLE_DS_PROGRAM': 'Can manage the programs',
  'ROLE_PORTAL_OPENING': 'Can manage the opening hours',
  'ROLE_PORTAL_INVENTORY': 'Can view  the inventories',

  'GO_BACK': 'Go Back',
  'PRIVACY_POLICY': 'Privacy Policy',
  'TERMS_OF_USE': 'Terms Of Use',

  'CLUF_TERMS_OF_USE': 'TERMS OF USE',
  'CLUF_TERMS_OF_USE_TEXT':
    'These general terms define the conditions to access and use the MCube website and related sites (hereinafter the Site) as well as the terms of use of interactive services (the Services) offered and maintained for you (hereinafter User), by MCube, on their Site. The Services are accessible to MCube customers only (hereinafter the Customers) for the duration of the audio contract (audio and/or visual) (hereinafter the Contract) they have concluded with MCube. These also include hosting services, the sending of newsletters, the provision of content created at the customer’s request and of tools used to create multimedia content and message programming as well as audiovisual campaigns (hereinafter the Content). Services also include the provision of tools for managing the Customer’s software (hereinafter Software) as well as the after-sales service. The use of Services means that you agree with these Terms of Use. Some additional or special terms may apply to specific Services. These additional conditions will therefore become available with those specific services.',

  'CLUF_USE_OF_OUR_SERVICES': 'Use of our Services',
  'CLUF_USE_OF_OUR_SERVICES_TEXT':
    'You agree to respect the rules applicable to the Services provided to you and to not use them inappropriately. Specifically, you shall not cause any interference with our Services or attempt to gain access to our Site or our Services by using another method than the interface and the instructions we provide you with. You shall only use our Services in accordance with the regulations in force. We may suspend or terminate access to our Site and provision of our Services if you do not abide by the conditions or applicable regulation, or if we have any supported suspicions of improper use. You shall not copy, modify, distribute, sell or rent a portion or all of our Site, Services or of the software that fall within in. Similarly, you shall not decompile or attempt to extract the source code of such software, except in cases when the right to decompilation is authorised by law and within the limits laid down by this law, or if you have obtained our prior written approval. The use of our Site and Services shall not grant you any right of use or any intellectual property right on the content accessed or created from our Content, except otherwise specified in the Contract. The Content includes all information, brands, creations protected or not by intellectual property law, such as photographs, music, texts, videos, software, charts, management tools…. Our Site and Services may display Content that does not belong to us, which may have been provided by our Customers. This Content is the sole responsibility of the Customer or the entity who made it available. We may have to check the Content to ensure its compliance with the applicable regulation or with our Terms of Use and it may be removed in case of non-compliance. Specifically, we may disable the accounts of Users who infringed copyrights and related rights. However, the fact that we reserve the rights of verification and deletion does not mean that we necessarily check the Content.',

  'CLUF_PROTECTION_OF_PRIVACY': 'Protection of privacy',
  'CLUF_PROTECTION_OF_PRIVACY_TEXT':
    'MCube Privacy Policy explains how we process your personal data and protect your privacy while you are using our Site and Services. You expressly agree to these confidentiality rules before using our Services or subscribing to newsletter.',

  'CLUF_CONTENT': 'Content',
  'CLUF_CONTENT_TEXT':
    'Some of our Services allow you to import, submit, store, send or receive content. You retain all of your intellectual property rights on such content. You warrant MCube that the content you post on the Site is legal and its information accurate, specifically your business data. When you import, submit, store, send or receive content through our Services, you grant MCube a license, for the duration of your Contract, worldwide, for the use, hosting, storage, reproduction, modification, creation of derivative works (translations, adaptations or other changes designed to improve the functioning of your content through our Services), communication, publication, public representation, public display or public distribution of such content. The use of Content created for you and made available by MCube is subject to the terms, conditions and limitations of the Contract you entered into. MCube shall not be held responsible for any use outside the terms of the Contract.',

  'CLUF_AMENDMENT_AND_TERMINATION': 'Amendment and termination of our Services',
  'CLUF_AMENDMENT_AND_TERMINATION_TEXT':
    'MCube regularly modifies and improves its Services. MCube might add or remove features, but might also suspend, cease to provide you with all or part of the Services, or add or impose new limits to the use of the Services, at any time.',

  'CLUF_WARRANTIES_AND_DISCLAIMERS': 'Warranties and disclaimers',
  'CLUF_WARRANTIES_AND_DISCLAIMERS_TEXT':
    'The Site and the Services provided by MCube are subject to an obligation of means. There is a disclaimer of warranty within the limits permitted by law. MCube shall not provide any warranty under these Terms of Use regarding the content of the Site and Services, their specific features, reliability, availability, or their suitability for your needs. Nor shall MCube be held responsible for the possible incompatibility of the tools and software used by you to access our Services. Within the limits of the law, MCube shall not be liable for any loss of profits, of revenue or data, or for any indirect, special, consecutive, special, consequential, exemplary or punitive damages you may suffer. In any case, in respect of all claims in connection with these Terms of Use, the liability of MCube shall be limited to reasonably foreseeable loss or damages and to the amounts paid to use the Services.',

  'CLUF_ACCESS_RIGHTS': 'Access rights to our Services',
  'CLUF_ACCESS_RIGHTS_TEXT':
    'When you use our Services on behalf of a company, you are acting on behalf of that company and you have already obtained the required permissions, under its operating rules, to act as administrator and/or user of the interface and interactive Services made available to you.',

  'CLUF_CHANGES_TO_THE_TERMS': 'Changes to the Terms and Conditions of Use',
  'CLUF_CHANGES_TO_THE_TERMS_TEXT':
    'Changes to the Terms and Conditions of Use shall not apply retroactively and shall come into force at least eight (8) days after they are published. However, changes specific to a new feature in a Service or amendments made for legal reasons shall apply immediately. If you do not accept the changes to these Terms of Use you must cease all use of our Services.',

  'CLUF_APPLICABLE_LAW': 'Applicable Law ',
  'CLUF_APPLICABLE_LAW_TEXT':
    'In the event of a conflict between these Terms of Use and/or additional conditions and/or the Contract, the Contract supersedes the additional conditions. If you do not respect these Terms of Use and if we do not take immediate action in this regard, it does not mean we are waving our rights. If it becomes apparent that a clause/condition is not applicable, this will not affect the other provisions of these Terms of Use. These Terms of Use are subject to Belgian law and to the French-speaking courts of Brussels, within the limits of the law, in particular with regard to Users who may not be corporate entities.',

  'SCOPE_OF_APPLICATION': 'Scope of application ',
  'SCOPE_OF_APPLICATION_TEXT':
    'During your visit, while using our Site and our Services, MCube collects, processes and, if necessary, transfers your personal data in accordance with the applicable regulations (General Data Protection Regulations on Personal Data and the Belgian national legislation). New technologies are essential to our business and the services we offer, such as content production, the availability of tools to create multimedia contents, and the programming of messages and audiovisual campaigns. This Privacy Policy describes what measures are taken to ensure the protection of your privacy while using the Site and/or Services and how to protect your rights in this respect as a User and/or Customer. It defines how your personal consent is collected, as well as the purposes of the processing. By accessing and continuing to browse the Site and/or by using the Services, you acknowledge that you have carefully read, acknowledged and accepted, without any restriction, nor reserve, this Privacy Policy. Future adjustments cannot be excluded; any adjustment made will be published on this page. We therefore advise you to regularly read the said Privacy Policy.',

  'CONTROLLER': 'Controller',
  'CONTROLLER_TEXT':
    'The controller is Storever Belgium SPRL, located Boulevard International 55 in 1070 Anderlecht, Belgium, registered with the Corporation Register under number 0666834913 ; contact person: support@mcubedigital.com;',

  'DATA_WE_COLLECT': 'Data we collect',

  'STANDARD_DATA': 'Standard Data',
  'STANDARD_DATA_TEXT':
    'Each time the Site is visited or the Services are used, including when you create an account, the following personal data are collected:',

  'YOUR_IP_ADDRESS': 'Your IP address',
  'THE_DNS_ADDRESS': 'The DNS address',
  'THE_DATE_AND_DURATION': 'The date and duration of your visit on the Site,  ',
  'THE_PAGES_YOU_VISIT': 'The pages you visit on the Site, ',
  'YOUR_BROWSER': 'Your browser, ',
  'URL_REFERRER': 'URL Referrer ',

  'PERSONAL_CONTACT_DATA': 'Personal contact data',
  'PERSONAL_CONTACT_DATA_TEXT':
    'In order to use the Services offered on our Site as well as to make Contents and Programs available, you will create or finalise a user account (hereinafter the \'User Account\'), under which you will provide the following personal data :',

  'YOUR_FIRST_AND_LAST_NAMES': 'Your first and last names, your gender,',
  'YOUR_OCCUPATION': 'Your occupation, within the Client company when applicable ; ',
  'YOUR_EMAIL_ADDRESS': 'Your email address,',
  'YOUR_WORK_PHONE_NUMBER': 'Your work phone number (mobile and/or land line), fax number, skype, optional,',
  'YOUR_TIME_ZONE': 'Your time zone, optional too.',

  'THESE_DATA_ARE_ALSO_COLLECTED':
    'These data are also collected and processed, in order to deliver the Players and provide you with specific assistance. We also use this information to check if a third party may be using your email address to grant authorisations without your approval. In addition, the following data shall be kept for throughout the performance of the Contract:',

  'PHONE_CALLS': 'Phone calls,',
  'MESSAGES_SENT_THROUGH': 'Messages sent through our online messaging service ',

  'NO_SENSITIVE_DATA_SHALL_BE_COLLECTED': 'No sensitive data shall be collected.',

  'NEWSLETTER': 'Newsletter',
  'NEWSLETTER_TEXT':
    'We may send you commercial prospecting emails (\'Newsletters\') if you have given us consent to do so. Our Newsletters include information on our existing services as well as our new services. You can unsubscribe from Newsletters by clicking on the unsubscribe link that is added to our Newsletters. Please Note If you ask us to stop sending you Newsletters we will keep a record of your personal data and of your request to ensure that you will not be receiving our Newsletters.',

  'CONNECTION_TO_THE_SERVICE': 'Connection to the service and to content from third parties',
  'CONNECTION_TO_THE_SERVICE_TEXT':
    'Sometimes, content from third parties - for example, videos from YouTube, maps from Google Maps, RSS databases, or graphics from other sites - may be incorporated with the online offer. This also means that the content owner (in reference to the third party owner) collects the user’s IP address. This makes sense because without an IP address, the content cannot be sent to the respective users. The IP address is also required for the representation of content. We make sure to only use content that owners send directly through the IP address. Moreover, we have absolutely no control on how the third party will store the IP address, e.g. for statistical analyses. If we become aware of such practices, we will inform the customer.',

  'COOKIES': 'Cookies',
  'COOKIES_TEXT':
    'Cookies are text files that allow us to store information on your client computer. Information about cookies may be used to maintain configurations specific to each visit, to provide certain Site features and to learn more about how visitors use our Site (user name, language and other display settings). This information helps us improve our Site and provide you with the best possible user experience. All common Web browser software applications offer options to block and remove cookies from your computer. Keep in mind that blocking or deleting cookies may diminish your user experience or prevent you from using certain features from our Site. Analytic cookies allow us to receive anonymous statistical information about the number of visits to our site in order to optimize it and detect any malfunctions. They are stored on our sites and applications by Google Analytics. If you do not want to allow our sites and services to store cookies on your browser in order to measure the site’s audience, you can click on the following link to find additional information: Google Analytics Cookies https ://tools.google.com/dlpage/gaoptout We use the Doubleclick platform to manage advertising display on our websites. You can click on the following link to manage cookies from Doubleclick. https ://www.google.com/settings/u/0/ads/authenticated',

  'PURPOSE_OF_YOUR_DATA_PROCESSING': 'Purpose of your data processing',
  'WE_ONLY_COLLECT_AND_PROCESS': 'We only collect and process your data for the purposes expressly specified:',

  'YOUR_CONSENT_TO_RECEIVE_OUR_NEWSLETTERS': 'your consent to receive our newsletters,',
  'THE_USE_OF_OUR_SITE_AND_SERVICES': 'the use of our Site and Services to manage your software applications,',
  'the_implementation_of_the_service': 'the implementation of the service contract that binds us to your company. ',

  'HOW_LONG_YOUR_DATA_WILL_BE_STORED': 'How long your data will be stored',
  'HOW_LONG_YOUR_DATA_WILL_BE_STORED_TEXT':
    'We will retain your data only for the time required for the provision of the subscribed Services. Beyond that period, it will be stored for statistical purposes only, or to satisfy legal requirements, and it will not be exploited in any way.',

  'TRANSFER_OF_YOUR_DATA': 'Transfer of your data',
  'TRANSFER_OF_YOUR_DATA_TEXT':
    'We store your personal data within the European Union. However, it may be marginally transferred to subcontractors or commercial partners located in other countries. In the event of such transfer, we shall make sure that the process is in compliance with this privacy policy and regulated by the standard contractual clauses of the European Commission which ensure an adequate level of protection for personal data and the fundamental rights of individuals.',

  'SECURITY': 'Security',
  'SECURITY_TEXT':
    'We use technical measures such as the use of passwords or encryption to protect your data as well as the systems where they are stored. We also carry out operational responses for data protection, for example by limiting the number of people who can access the databases. We make sure that these security measures are regularly updated and we rely on the industry safety standards to stay informed about the best practices. We apply the same security requirements with respect to our subcontractors and partners. We make sure they are contractually committed to take all the technical and organisational measures necessary to protect personal data against the accidental or unauthorised destruction, accidental loss as well as any change, access and any other unauthorised processing of personal data.',

  'RIGHTS_OF_ACCESS_AND_RECTIFICATION': 'Rights of access and rectification',
  'RIGHTS_OF_ACCESS_AND_RECTIFICATION_TEXT':
    'You may view copies of all the personal data in our possession and request to modify, rectify or delete your personal data. You may also limit, restrict or oppose the processing of your data. You can connect on the Site in order to update the information contained therein. If you have given us your consent to let us use your data, for example to send you commercial solicitations, you are entitled to immediately withdraw your consent, thanks to the hyperlink that is inserted in the body of the Newsletter. However, we can always base ourselves on the consent you provided us, as a legal basis for the processing of data that was carried out prior to the withdrawal of your consent. To raise any objection or exercise any of your rights, you may contact us under the conditions referred to in the section \'Claims\' below. When you contact us, we will answer you as soon as we are able to do so and in any case within two months. If your request is more complex, our response time may be longer, but we will get back to you within two months maximum. Most requests will be processed free of charge, but we may ask you to pay reasonable administration fees, for instance if you are requesting a large quantity of data. We may also ask you to verify your identity before we can provide you with any information.',

  'CLAIMS': 'Claims',
  'CLAIMS_TEXT':
    'If you have any complaints with regard to the processing of your personal data by MCube, please us send an email to support@mcubedigital.com or write to us at the following address: Data Protection, SPRL Storever Belgium, located Boulevard International 55 in 1070 Anderlecht, Belgique. Please note that you have the right to file a complaint with a supervisory authority for the protection of personal data in the country where you live or work or in the country where you feel that a violation of the laws on data protection might have taken place. Customers in Belgium can contact the Data Protection Authority by dialing the + 32 (0) 2 274 48 00 or the can use the online contact form, which is available on the website of the Authority. Visit www.autoriteprotectiondonnees.be.',

  'Go_BACKOFFICE_MESSAGE': 'See in backoffice',
  'ERROR_USER_CODE_NOT_FOUND': 'The token is incorrect or has expired, please request password reset again.',
  'ERROR_INVALID_PASSWORD': 'La contraseña es incorrecta o idéntica a la anterior.',
  'ERROR_USER_EMAIL_NOT_UNIQUE': 'Error multiple user exists with this address email, please contact the Dev team',
  'TICKET_LIST_PAGE_TITLE': 'Portal | Tickets',
  'TICKET_DETAILS_PAGE_TITLE': 'Portal | Tickets Details',
  'TICKETS': 'Tickets',
  'TICKETS_LIST_TITLE': 'Tickets',
  'TICKETS_ISSUE_TYPE': 'Issue type',
  'TICKETS_DESCRIPTION': 'Description',
  'TICKETS_DATE_CREATION': 'Creation',
  'TICKETS_DATE_CLOSE': 'Close',
  'TICKETS_IS_CLOSE': 'Status open',
  'TICKET_ID': 'Ticket id',
  'PLAYER_SUBSCRIPTION': 'Subscription',
  'TICKET_LIST_FILTER_SUBSCRIPTION': 'Subscription name',
  'TICKET_INFO_TITLE': 'Ticket info',
  'TICKET_CREATED': 'Created',
  'TICKET_CLOSED': 'Closed',
  'TICKET_DEVICE_ID': 'Device id',
  'TICKET_MODEL': 'Device id',
  'TICKET_STATUS': 'Status',
  'TICKET_STATUS_OPENED': 'OPENED',
  'TICKET_STATUS_CLOSED': 'CLOSED',
  'SUBSCRIPTION_INFO': 'Subscription info',
  'TODO_INFO': 'Todo info',
  'TODO_TYPE': 'Todo type',
  'TODO_USER_ASSIGN': 'User assign',
  'TODO_DESCRIPTION': 'Description',
  'TODO_ACTION': 'Action',
  'TODO_ACTION_DATE': 'Action date',
  'TICKET_ADDRESS': 'Address',
  'TICKET_CONTACT': 'Contact',
  'TICKET_LIST_QUICK_SEARCH': 'Quick search by player id , description or todo ',
  'MUSIC_PREVIEW_TITLE': 'Music Preview',
  'MUSIC_PREVIEW_LIST_PAGE_TITLE': 'Portal | Music Preview',
  'MUSIC_PREVIEW_LINK_MODAL_PAGE_TITLE': 'Link station and boxes to your client',
  'MUSIC_PREVIEW_IMPORT_STATION_DIALOG': 'Stations',
  'MUSIC_PREVIEW_DIALOG_STATION_NONE': '(select a station)',
  'MUSIC_PREVIEW_DIALOG_BOXES_NONE': '(select a box or playlist)',
  'MUSIC_PREVIEW_IMPORT_BOXES_DIALOG_PLAYLIST': 'Boxes and playlist',
  'MUSIC_PREVIEW_DIALOG_CONFIRM': 'Save',
  'AUDIO_PLANNINGS_ARTISTS_NAME': 'Artists',
  'MUSIC_PREVIEW_RATING': 'Rating',
  'AUDIO_PLANNINGS_TITLE': 'Title',
  'AUDIO_PLANNINGS_YEAR': 'Year',
  'BOX_PLAYLIST_LOAD_ERROR': 'Box playlist load error',
  'RATTING_SUCCESS': 'Rating update success',
  'STATIONS_LOAD_ERROR': 'Station success',
  'RATTING_ERROR': 'Rating update error',
  'CREATE_BOX_STATION_CLIENT_SUCCESS': 'Create link between client , station and box success',
  'CREATE_BOX_STATION_CLIENT_ERROR': 'Create link between client , station and box error',
  'TRACK_LIST_BAD_REQUEST_ERROR': 'Track list Bad request',
  'TRACK_LIST_LOAD_ERROR': 'Tracks list load error',
  'ROLE_PORTAL_MUSICPREVIEW': 'Can view music preview',
  'MUSIC_PREVIEW_NO_LINK_MSG_1': 'The preview of music is not planned.',
  'MUSIC_PREVIEW_NO_LINK_MSG_2': 'Please ask your producer to plan preview music.',
  'MUSIC_PREVIEW_NO_RULE_MSG_1': 'You don\'t have enough right.',
  'MUSIC_PREVIEW_NO_RULE_MSG_2': 'Please ask your producer to update your right.',
  'STATION_BOX_CLIENT_DELETE_SUCCESS': 'Station box client unlink success',
  'STATION_BOX_CLIENT_DELETE_FAIL': 'Station box client unlink fail',
  'ROLE_BO_SETUP': 'Can Setup Player',
  'ROLE_BO_ORDER': 'Can manage Order',
  'ROLE_BO_CANCLOSESITE': 'Can close site',
  'ROLE_BO_SHIPPING_VIEW': 'Can view shipping',
  'ROLE_BO_BILLING_VIEW': 'Can view  billing',
  'ROLE_BO_BILLING_EDIT': 'Can manage billing',
  'ROLE_BO_SHIPPING_EDIT': 'Can manage shipping',
  'ROLE_BO_PLAYERCONFIG_VIEW': 'Can view Player configuration',
  'ROLE_BO_PLAYERCONFIG_EDIT': 'Can manage Player configuration',
  'ROLE_BO_BILLING_CHANGE_CURRENCY': 'Can change the currency of a contract',
  'ROLE_BO_ORDERCANCEL_PICKED': 'Can cancel an order even if player is picked',
  'ROLE_BO_MANAGER_PLAYER': 'Can manage a player from a subscription',
  'ROLE_BO_BILLING_INVOICES_VIEW': 'Can view invoices',
  'ROLE_BO_BILLING_INVOICES_CREATE': 'Can create invoices',
  'ROLE_BO_BILLING_EXECUTE': 'Can launch billing calculation',
  'ROLE_BO_REPORTING_SUPPORT': 'Can view report support stats',
  'ROLE_BO_BILLING_SALES_DASHBOARD_EDIT': 'Can edit content and target in Sales dashboard',
  'REASON_DONT_LIKE_THE_SONG_TITLE': 'Please express why you dislike this song.',
  'REASON_DONT_LIKE_THE_SONG': 'Reason',
  'AUDIO_PLANNINGS_COMMENT': 'Comments',
  'SAVE': 'Save',
  'PLAYERS_ISSUE_CLIENT_NAME': 'Client',
  'PLAYERS_ISSUE_LIST_TITLE': 'Player issues',
  'PLAYERS_ISSUE_LIST_UPDATED': 'Updated',
  'PLAYERS_ISSUE_LIST_CREATED': 'Creation date',
  'PLAYERS_ISSUE_LIST_CLOSED': 'Closed',
  'PLAYERS_ISSUE_LIST_TYPE_ISSUE': 'Type',
  'PLAYERS_ISSUE_LIST_TYPE_DESCRIPTION': 'Description',
  'PLAYERS_ISSUE_LIST_CLIENT': 'Client',
  'PLAYERS_ISSUE_LIST_START_DATE': 'Start date',
  'PLAYERS_ISSUE_FILTER_START_DATE': 'Created start date',
  'PLAYERS_ISSUE_FILTER_CREATION_START_DATE': 'Creation start date',
  'PLAYERS_ISSUE_LIST_END_DATE': 'End date',
  'PLAYERS_ISSUE_FILTER_END_DATE': 'Created end date',
  'PLAYERS_ISSUE_FILTER_CREATION_END_DATE': 'Creation end date',
  'PLAYERS_ISSUE_LIST_QUICK_SEARCH': 'Quick search by site and client',
  'ROLE_PORTAL_PLAYERISSUES': 'Can view Player issue',
  'ROLE_PORTAL_ADVANCED_USER_MANAGEMENT': 'Can view advanced user management',
  'PRODUCTION_LINE_BXL': 'Production line Brussels',
  'PRODUCTION_LINE_SHA': 'Production line Shanghai',
  'PRODUCTION_LINE_HON': 'Production line Hong kong',
  'PRODUCTION_LINE_TRI': 'Production line Trieste',
  'PRODUCTION_LINE_MIL': 'Production line Milano',
  'PRODUCTION_LINE_PAR': 'Production line Paris',
  'PRODUCTION_LINE_EXT': 'External Partners',
  'PLAYERS_ISSUE_FILTER_IS_POSTPONED': 'Postponed',
  'PLAYERS_ISSUE_FILTER_TICKET_STATUS': 'Ticket status',
  'PLAYERS_ISSUE_FILTER_TICKET_STATUS_1': 'Tickets closed',
  'PLAYERS_ISSUE_FILTER_TICKET_STATUS_2': 'Tickets in progress',
  'PLAYERS_ISSUE_FILTER_TICKET_STATUS_3': 'No tickets',
  'PLAYERS_ISSUE_FILTER_TICKET_STATUS_4': 'No tickets or in progress',
  'PLAYERS_ISSUE_FILTER_TICKET_STATUS_5': 'With tickets',
  'PLAYERS_ISSUE_FILTER_TICKET_STATUS_6': 'All',
  'PLAYERS_ISSUE_FILTER_HAS_TICKET': 'Has ticket',
  'PLAYERS_ISSUE_LIST_TICKET_LABEL': 'Ticket',
  'PLAYERS_ISSUE_POSTPONED_MODAL_TITLE': 'Postpone issue for',
  'PLAYERS_ISSUE_POSTPONED_MODAL_FORM_REASON': 'Reason',
  'PLAYERS_ISSUE_POSTPONED_MODAL_FORM_OTHERS': 'Please input the reason',
  'PLAYERS_ISSUE_DETAIL_MODAL_FORM_OTHERS': 'Other reason',
  'PLAYERS_ISSUE_POSTPONED_MODAL_FORM_DELAY': 'Delay',
  'PLAYERS_ISSUE_POSTPONED_MODAL_FORM_POSTPONE_TO': 'Postponed to',
  'PLAYERS_ISSUE_POSTPONED_MODAL_FORM_REQUIRED': 'This info is mandatory',
  'PLAYERS_ISSUE_POSTPONED_CREATE_SUCCESS': 'Postpone updated success',
  'PLAYERS_ISSUE_POSTPONED_CREATE_FAILED': 'An error occurred while postponing the issue.',
  'WAITING_CUSTOMER_FEEDBACK': 'Waiting for customer feedback',
  'CUSTOMER_NETWORK_ISSUE': 'Waiting for network issue (customer)',
  'PARTNER_RESPONSE': ' Waiting for partner response',
  'WAITING_INTERNAL_TECHNICAL_ESCALATION': ' Waiting for internal technical escalation',
  'WAITING_INTERNAL_SALE_ESCALATION': ' Waiting for internal escalation sales',
  'WAITING_INTERNAL_DOCUMENTATION_REQUEST': 'Waiting for internal doc request',
  'WAITING_RELATED_CASE': ' Waiting for related case',
  'Waiting_OPEN_ACTIVITY': 'Waiting for open activity',
  'LEVEL_1_FAULT': ' Level1 fault',
  'OTHER': 'Other',
  'CREATE_TICKET_DIALOG_TITLE': 'Create ticket to Salesforce',
  'CREATE_TICKET_DIALOG_NAME': 'Description',
  'CREATE_TICKET_DIALOG_NAME_PLACEHOLDER': 'Description',
  'CREATE_TICKET_DIALOG_NAME_REQUIRED': 'This input is required',
  'CREATE_TICKET_DIALOG_ACCEPT': 'Create',
  'CREATE_TICKET_DIALOG_SUBJECT': 'Subject',
  'CREATE_TICKET_DIALOG_HELPDESK': 'Helpdesk',
  'PLAYERS_ISSUE_LIST_FILTER_SUBSCRIPTION': 'Subscription - ALI',
  'PLAYERS_ISSUE_FILTER_ISSUE_TYPES': 'Issue type',
  'ROLE_BO_ANOMALIES_MULTIPLEDEFAULT_PLAYER': 'Allow to view anomalies with multi default player',
  'ISSUE_TYPES_LOAD_ERROR': 'Issue type list load error',
  'ISSUE_DETAIL_LOAD_ERROR': 'Issue detail load error',
  'SF_TICKET_CREATION_SUCCESS': 'SalesForce ticket has been created successfully',
  'SF_TICKET_CREATION_ERROR': 'An error occurred while creating SalesForce ticket',
  'SF_TICKET_DETAIL_LOAD_ERROR': 'SalesForce Ticket load error',
  'SF_TICKETS_CREATION_ERROR': 'An error occurred while creating SalesForce ticket for',

}
